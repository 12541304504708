<style scoped>
div.wrapper {
	position: relative;
	padding: 10px 2%;
    padding-bottom: 100px;
	min-height: 100vh;
	box-sizing: border-box;
}

div.header {
	display: block;
	grid-template-columns: 1fr 0.7fr 0.3fr 1fr;
	margin-bottom: 10px;
	align-items: center;
	text-align: center;
}

div.header > select {
	width: auto;
	background-color: transparent;
	border: 0;
	text-transform: uppercase;
	text-align: center;
	font-size: 18px;
	height: 40px;
	display:block;
	margin:auto;
}

div.header > input {
	background-color: white;
	border: 0;
	border-radius: 8px;
	padding: 10px;
	width: 200px;
	display:block;
	margin: 10px auto 0px auto;
}

div.menu {
	padding: 0 5%;
}

div.subsection {
    margin-bottom: 25px;
}

div.subsection > .subsection-header {
    z-index: 100;
	background-color: #f7f7f7;
    position: sticky;
    padding: 5px 0;
    top: 0;
}

div.subsection-controls {
    display: inline-flex;
    align-items: center;
}

div.subsection-controls input.subtitle-name {
    font-size: 20px;
    font-weight: 700;
    outline: none;
    border: 0;
    border-radius: 8px;
    padding: 5px 15px;
    padding-left: 40px;
    margin-right: 10px;
}

div.subsection-controls div.position-buttons {
    float: none !important;
    display: inline;
}

div.subsection-items {
	padding: 0 5%;
}

div.item {
	background-color: white;
	box-sizing: border-box;
	padding: 20px 30px;
	border-radius: 8px;
	margin: 20px 0;
	display: flex;
}

div.item input.name,
div.item input.name {
	font-weight: 700;
	font-size: 20px;
}

div.item input.shortDescription {
	font-weight: 400;
	font-size: 15px;
}

div.item textarea.longDescription {
	font-weight: 300;
	color: #a9a9a9;
	margin-bottom: 0;
	font-size: 14px;
}

div.item div.price > span.simbol {
	font-size: 28px;
    display: inline;
	font-weight: 600;
	color: #797979;
	margin-right: 5px;
}

div.item div.price > input {
	font-size: 28px;
	font-weight: 600;
	width: 80px;
	color: #797979;
}

div.item > div.buttons {
    display: none;
}

div.item > div.left,
div.item > div.right {
    box-sizing: border-box;
    flex-direction: column;
    display: inline-flex;
}

div.item > div.left {
    width: 60%;
}

div.item > div.right {
    padding-left: 10px;
    width: 40%;
}

div.item.product > div.right > div.status {
    display: flex;
    align-items: center;
}

div.item > div.left > * + * {
    margin-top: 10px;
}

div.add-buttons {
    margin-left: 50px;
}

button.btn-add {
	background-color: transparent;
	color: #70b1fd;
	border: 2px solid #70b1fd;
	font-weight: 600;
}

button.btn-add + button.btn-add {
    margin: 0;
    margin-left: 10px;
}

button.btn-export {
	background-color: #70b1fd;
	color: white;
    font-size: 80%;
}

div.publish {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(247, 247, 247, 0.8);
	position: fixed;
    padding: 10px 0;
	z-index: 200;
	bottom: 0;
	right: 20px;
	left: 0;
}

div.publish > span.loading {
	position: absolute;
	font-size: 14px;
	font-weight: 300;
    color: grey;
	right: 100px;
}

button.btn-publish {
	background-color: #70b1fd;
	color: white;
	border: 2px solid #70b1fd;
	font-weight: 600;
	padding-left: 50px;
	padding-right: 50px;
}

@media (max-width: 768px) {
    div.subsection > .subsection-header {
        position: initial;
    }

    div.subsection-items {
        padding: 0;
    }

    div.item {
        flex-direction: column;
    }

    div.item div.desktop-buttons {
        display: none;
    }

    div.item > div.buttons {
        justify-content: flex-end;
        align-items: flex-start;
        box-sizing: border-box;
        display: flex;
    }

    div.item > div.buttons,
    div.item > div.left,
    div.item > div.right {
        width: 100%;
        align-items: center;
    }

	div.item.product > div.right {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #CCCCCC;
        border-right: 0;
        padding-top: 10px;
        padding-right: 0px;
	}

    div.item.product > div.right {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    div.item.product > div.right > div.options {
        margin-left: 15px;
    }

    div.item.product > div.right > div.options,
    div.item.product > div.right > div.status {
        display: inline-flex;
    }

    div.subsection-controls div.position-buttons {
        display: block;
    }

    div.add-buttons {
        text-align: center;
        margin: 0;
    }

    button.btn-add {
        width: 100%;
    }

    button.btn-add + button.btn-add {
        margin: 0;
        margin-top: 10px;
    }

    div.publish {
        flex-direction: column;
    }

    div.publish > span.loading {
        position: relative;
        display: inline-block;
    }
}

div.item input,
div.item textarea,
div.item input {
	width: 100%;
	display: inline-block;
	background: transparent;
	border: 0;
}

div.footer {
    display: inline;
	grid-template-columns: 1fr 2fr;
}

div.options {
	display: inline;
	align-items: center;
	justify-content: flex-end;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}


div.price {
    display: inline;
}

.trash {
	content: url("../../assets/img/arrow.svg");
}

.deleteButton {
	vertical-align: middle;
	outline: none;
    background: transparent;
    border: none;
	cursor: pointer;
}

.moverButton {
	vertical-align: middle;
	outline: none;
    background: transparent;
    border: none;
	cursor: pointer;
}

#alertMessage {
	position:fixed; 
	top: 0px; 
	left: 0px;
	text-align: center;
	width: 100%;
	background: #70b1fd;
	z-index:9999;
	opacity: 1;
	border-radius:0px
}

div#fatal-error {
    background-color: rgb(255, 86, 86);
    text-align: center;
    border-radius: .25rem;
    padding: 10px;
    position: fixed;
    top: 5px;
    width: 100%;
    color: white;
    z-index: 10000;
}
</style>

<template>
	<div class="wrapper">

        <div v-if="fatalError" id="fatal-error">
            Ocurrió un error al <strong>{{ fatalError }}</strong>.
            <br>
            Por favor, reinicie la aplicación y vuelva a intentarlo.
        </div>

		<transition name="fade">
			<div v-if="showPublishingResultAlert" id="alertMessage">
				<div style="padding: 5px;">
					<div id="inner-message" class="topalert">
						{{ publishingResultText }}
					</div>
				</div>
			</div>
		</transition>

		<loading :active="publishingMenu" :can-cancel="false" color="#70b1fd" :is-full-page="true" />

		<div class="header">
			<select v-model="selectedMenuId">
				<option :value="null" disabled>Seleccione un menú</option>
				<option v-for="option in menusSelect" :key="option.id" :value="option.id">{{ option.name }}</option>
			</select>
			<input v-if="selectedMenuId" type="text" v-model="search" placeholder="Buscar producto por nombre..." />
            <br>
            <button v-if="selectedMenuId" class="btn btn-filled btn-export" @click="exportToWord">
                <span>EXPORTAR MENÚ A WORD</span>
            </button>
		</div>

		<div v-if="selectedMenuId" class="publish">
			<button v-if="showPublishMenuButton" class="btn btn-filled btn-publish" @click="publishMenu(selectedMenuId)">PUBLICAR MENÚ</button>
			<span class="loading">{{ updatingProduct ? 'Guardando...' : 'Cambios guardados' }}</span>
		</div>

		<div v-if="selectedMenu" class="menu">
			<div v-for="(section, index) in selectedMenu.menuMainSections" :key="index" class="section">
				<div v-for="(subsection, subIndex) in section.menuSubSections" :id="`subsection-${subsection.id}`" :key="subIndex + subsection.headerRenderData.name" class="subsection">
                    <small v-if="showPositionIndex">{{ subsection.positionIndex }}</small>
                    <!-- Información de la subsección -->
                    <h2 class="subsection-header">
                        {{ section.headerRenderData.name }}
                        <div class="subsection-controls">
                            <span v-if="!canEditSubSections(section)">
                                <template v-if="subsection.headerRenderData.name && section.headerRenderData.name != subsection.headerRenderData.name">
                                    - {{ subsection.headerRenderData.name }}
                                </template>
                            </span>
                            <template v-else>
                                -
                                <auto-input 
                                    v-model="subsection.headerRenderData.name"
                                    placeholder="Grupo sin título"
                                    style="margin-left: 10px"
                                    class="subtitle-name"
                                    @input="(value) => updateSubSection(index, subIndex, 'headerRenderData.name', value)"
                                />
                            </template>
                            <div v-if="canEditSubSections(section)" class="position-buttons">
                                <button v-if="subsection.prevElement" class="moverButton" @click="changeSubSectionIndex(selectedMenuId, index, subIndex, subsection, -1)">
                                    subir <img src="../../assets/img/arrowup.svg" />
                                </button>
                                <button v-if="subsection.nextElement" class="moverButton" @click="changeSubSectionIndex(selectedMenuId, index, subIndex, subsection, +1)">
                                    bajar <img src="../../assets/img/arrowdown.svg" />
                                </button>
                                <button class="deleteButton" @click="deleteSubSection(index, subIndex, subsection)">
                                    <img src="../../assets/img/delete.svg" />
                                </button>
                            </div>
                        </div>
                    </h2>
					<div class="subsection-items">
                        <template v-for="item in subsection.productsAndSubtitles">
                            <div class="item" :class="{'product' : item.type == 0, 'subtitle': item.type == 1}" v-if="showProduct(item)" :id="`item-${item.type}-${item.id}`" :key="`item-${item.type}-${item.id}`">
                                <small v-if="showPositionIndex" style="float: left">{{ item.positionIndex }}</small>
                                <div class="buttons">
                                    <button v-if="item.prevElement" class="moverButton" @click="changeIndex(selectedMenuId, index, subIndex, item, -1)">
                                        subir <img src="../../assets/img/arrowup.svg" />
                                    </button>
                                    <button v-if="item.nextElement" class="moverButton" @click="changeIndex(selectedMenuId, index, subIndex, item, +1)">
                                        bajar <img src="../../assets/img/arrowdown.svg" />
                                    </button>
                                    <button class="deleteButton" @click="deleteItem(index, subIndex, item)">
                                        <img src="../../assets/img/delete.svg" />
                                    </button>
                                </div>
                                <!-- Subtítulo -->
                                <template v-if="item.type == 1">
                                    <div class="left">
                                        <auto-input 
                                            v-model="item.subtitleName"
                                            placeholder="Subtítulo"
                                            class="name"
                                            @input="value => updateSubtitle(index, subIndex, item, 'subtitleName', value || 'Texto del subtítulo')"
                                        />
                                    </div>
                                    <div class="right">
                                        <div class="desktop-buttons">
                                            <button v-if="item.prevElement" class="moverButton" @click="changeIndex(selectedMenuId, index, subIndex, item, -1)">
                                                subir <img src="../../assets/img/arrowup.svg" />
                                            </button>
                                            <button v-if="item.nextElement" class="moverButton" @click="changeIndex(selectedMenuId, index, subIndex, item, +1)">
                                                bajar <img src="../../assets/img/arrowdown.svg" />
                                            </button>
                                            <button class="deleteButton" @click="deleteItem(index, subIndex, item)">
                                                <img src="../../assets/img/delete.svg" />
                                            </button>
                                        </div>
                                    </div>
                                </template>
                                <!-- Producto -->
                                <template v-else>
                                    <div class="left">
                                        <auto-input
                                            v-model="item.productName"
                                            placeholder="Ingrese el nombre del producto aquí"
                                            class="name"
                                            @input="value => updateProduct(item, 'productName', value || 'Nombre del producto')"
                                        />
                                        <auto-input
                                            v-model="item.shortDescription"
                                            v-if="item.isActive"
                                            placeholder="Ingrese la descripción corta aquí"
                                            class="shortDescription"
                                            @input="value => updateProduct(item, 'shortDescription', value)"
                                        />
                                        <auto-textarea
                                            v-if="!subsection.isSeccionBebidas && item.isActive"
                                            v-model="item.longDescription"
                                            placeholder="Ingrese la descripción larga aquí"
                                            class="longDescription"
                                            @input="value => updateProduct(item, 'longDescription', value)"
                                        />
                                    </div>
                                    <div class="right">
                                        <div class="status">
                                            <div class="price" v-if="item.isActive" style="margin-right: 15px">
                                                <span class="simbol">$</span>
                                                <auto-input-number
                                                    placeholder="0"
                                                    v-model="item.price.dinner"
                                                    @input="value => updateProduct(item, 'price.dinner', value || 0)"
                                                />
                                            </div>
                                            <toggle-button
                                                :width="70"
                                                v-model="item.isActive"
                                                :sync="true"
                                                :color="{checked: '#6BE29D', unchecked: '#BBBBBB'}"
                                                :labels="{checked: 'Activo', unchecked: 'Inactivo'}"
                                                @change="updateProduct(item, 'isActive', $event.value)"
                                            />
                                        </div>
                                        <div class="options" v-if="item.isActive">
                                            <!--<br>Mostrar Destacado:<toggle-button
                                                v-model="item.isNew"
                                                :sync="true"
                                                :color="{checked: '#6BE29D', unchecked: '#BBBBBB'}"
                                                :labels="{checked: 'si', unchecked: 'no'}"
                                                @change="updateProduct(item, 'isNew', $event.value)"/>-->
                                            <small style="margin-right: 5px">Plato del Día</small> 
                                            <toggle-button
                                                v-model="item.isPlatoDelDia"
                                                :sync="true"
                                                :color="{checked: '#6BE29D', unchecked: '#BBBBBB'}"
                                                :labels="{checked: 'si', unchecked: 'no'}"
                                                @change="updateProduct(item, 'isPlatoDelDia', $event.value)"
                                            />
                                        </div>
                                        <div class="desktop-buttons" style="margin-top: 10px">
                                            <button v-if="item.prevElement" class="moverButton" @click="changeIndex(selectedMenuId, index, subIndex, item, -1)">
                                                subir <img src="../../assets/img/arrowup.svg" />
                                            </button>
                                            <button v-if="item.nextElement" class="moverButton" @click="changeIndex(selectedMenuId, index, subIndex, item, +1)">
                                                bajar <img src="../../assets/img/arrowdown.svg" />
                                            </button>
                                            <button class="deleteButton" @click="deleteItem(index, subIndex, item)">
                                                <img src="../../assets/img/delete.svg" />
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
					</div>
                    <div class="add-buttons">
						<button class="btn btn-filled btn-add" @click="addProduct(index, subIndex)">
							<span>+ AGREGAR PRODUCTO</span>
						</button>
						<button class="btn btn-filled btn-add " @click="addSubtitle(index, subIndex)">
							<span>+ AGREGAR SUBTITULO</span>
						</button>
                    </div>
				</div>
                <button v-if="canEditSubSections(section)" class="btn btn-filled btn-add" @click="addSubSection(index)">
                    <span>+ AGREGAR GRUPO EN '{{ section.headerRenderData.name }}'</span>
                </button>
			</div>
		</div>
	</div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	data() {
		return {
			search: "",
			selectedMenuId: null,
		};
	},
	components: {
		Loading
	},
	computed: {
        showPositionIndex() {
            return false;
        },
		menusSelect() {
			return this.$store.getters["menusSelect"];
		},
		selectedMenu() {
			return this.selectedMenuId != null
				? this.$store.getters["menusList"][this.selectedMenuId]
				: null;
        },
		updatingProduct() {
			return this.$store.state.menu.updatingProduct;
		},
		publishingMenu() {
			return this.$store.state.menu.publishing == "running";
		},
		showPublishingResultAlert() {
			return this.$store.state.menu.publishing == "ok" || this.$store.state.menu.publishing == "error";
		},
		publishingResultText() {
			if (this.$store.state.menu.publishing == "ok") {return "Los cambios se publicaron correctamente.";}
			if (this.$store.state.menu.publishing == "error") 
			{
				if (this.$store.state.menu.publishedErrorMessage != "")
				{
					return this.$store.state.menu.publishedErrorMessage;
				}
				else
				{
					return "Hubo un error en la publicación, por favor intente nuevamente.";
				}
			}
			return "";
		},
		showPublishMenuButton() {
			if (this.$store.state.menu.publishing == "running")
			{
				return false;
			}

			return true;
        },
        fatalError() {
            return this.$store.state.menu.fatalError.context ?? null;
        }
	},
	methods: {
        canEditSubSections(section) {
            return section.canEditSubSections;
        },
		showProduct(product) {
			if (product.type == 0 && product.productName == undefined)
			{
				return false;
            }
            
            if (product.type == 1 && product.subtitleName == undefined)
			{
				return false;
            }
            
            let name = product.type == 0 ? product.productName : product.subtitleName;

			return name.toUpperCase().includes(this.search.toUpperCase());
		},
		addProduct(sectionIndex, subsectionIndex) {
			this.$store.dispatch("addProductToMenu", {
				menuId: this.selectedMenuId,
				sectionIndex,
				subsectionIndex,
			});
        },
        addSubtitle(sectionIndex, subsectionIndex) {
			this.$store.dispatch("addSubtitleToMenu", {
				menuId: this.selectedMenuId,
				sectionIndex,
				subsectionIndex,
			});
        },
        addSubSection(sectionIndex) {
			this.$store.dispatch("addSubSectionToMenu", {
				menuId: this.selectedMenuId,
				sectionIndex
			});
		},
		updateProduct(product, attribute, value) {
			this.$store.dispatch("updateProduct", { productId: product.id, attribute, value });
        },
        updateSubtitle(sectionIndex, subsectionIndex, subtitle, attribute, value) {
			this.$store.dispatch("updateSubtitle", { menuId: this.selectedMenuId, sectionIndex, subsectionIndex, subtitle, attribute, value });
        },
        updateSubSection(sectionIndex, subsectionIndex, attribute, value) {
			this.$store.dispatch("updateSubSection", { menuId: this.selectedMenuId, sectionIndex, subsectionIndex, attribute, value });
        },
        deleteItem(sectionIndex, subsectionIndex, item) {
            if(item.type == 0 && window.confirm('Estas seguro de eliminar el producto: ' + item.productName + '?'))
            {
                this.$store.dispatch("deleteProduct", { menuId: this.selectedMenuId, productId: item.id });
            }
            else if (item.type == 1 && window.confirm('Estas seguro de eliminar el subtítulo: ' + item.subtitleName + '?'))
            {
                this.$store.dispatch("deleteSubtitle", { menuId: this.selectedMenuId, sectionIndex, subsectionIndex, subtitle: item });
            }
        },
        deleteSubSection(sectionIndex, subsectionIndex, subsection) {
            if(subsection.productsAndSubtitles.length > 0)
            {
                alert(`Antes de eliminar el grupo ${subsection.headerRenderData.name} elimine todos sus elementos.`)
                return;
            }

			if(window.confirm('Estas seguro de eliminar el grupo: ' + subsection.headerRenderData.name + '?'))
			{
				this.$store.dispatch("deleteSubSection", { menuId: this.selectedMenuId, sectionIndex, subsectionIndex });
			}
        },
        changeSubSectionIndex(menuId, sectionIndex, subsectionIndex, subsection, indexChange) {
            let item = this.$el.querySelector(`#subsection-${subsection.id}`);

			if (indexChange < 0 && subsection.prevElement !== undefined)
			{
				this.$store.dispatch("changeSubsectionIndex", { menuId, sectionIndex, subsectionIndex, otherSubsectionIndex: subsectionIndex - 1 });
			}

			if (indexChange > 0 && subsection.nextElement !== undefined)
			{
				this.$store.dispatch("changeSubsectionIndex", { menuId, sectionIndex, subsectionIndex, otherSubsectionIndex: subsectionIndex + 1 });
            }
            
            this.$nextTick(() => item.scrollIntoView({ behavior: "smooth" }));
		},
		changeIndex(menuId, sectionIndex, subsectionIndex, product, indexChange) {
            let item = this.$el.querySelector(`#item-${product.type}-${product.id}`);

			if (indexChange < 0 && product.prevElement !== undefined)
			{
				this.$store.dispatch("changeProductsAndSubtitlesIndex", { menuId, sectionIndex, subsectionIndex, product: product, other: product.prevElement });
			}

			if (indexChange > 0 && product.nextElement !== undefined)
			{
				this.$store.dispatch("changeProductsAndSubtitlesIndex", { menuId, sectionIndex, subsectionIndex, product: product, other: product.nextElement });
            }
            
            this.$nextTick(() => item.scrollIntoView({ behavior: "smooth", block: 'nearest' }));
        },
        exportToWord() {
               var html, link, blob, url, css;

                css = (
                    '<style>' +
                    '@page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}' +
                    'div.WordSection1 {page: WordSection1;}' +
                    'table {width: 100%;}' +
                    'table, tbody, tr, td {border: 0px} ' +
                    '</style>'
                );

                html = this.selectedMenu.menuMainSections.map((section) => {
                    let menuSubSections = section.menuSubSections.map((subsection) => {
                        let table = null;
                        
                        if(subsection.productsAndSubtitles.length > 0) {
                            let showOnlyActives = (item) => (item.type == 0 && item.isActive) || item.type == 1;
                            let productsAndSubtitles = subsection.productsAndSubtitles.filter(showOnlyActives).map((item) => {
                                switch (item.type) {
                                    case 0:
                                        return `
                                        <tr>
                                            <td width="40%"><strong>${item.productName}</strong></td>
                                            <td width="40%">${item.shortDescription}</td>
                                            <td width="20%" style="text-align: right">$${item.price.dinner}</td>
                                        </tr>
                                        `;
                                    case 1:
                                        return `
                                        <tr style="background-color: whitesmoke">
                                            <td colspan="3"><strong>${item.subtitleName}</strong></td>
                                        </tr>
                                        `;
                                    default:
                                        return '';
                                }
                            });

                            table = `<table cellspacing="0"><tbody>${productsAndSubtitles.join('')}</tbody></table>`;
                        }

                        return !table ? '' : `<div><h3>${subsection.headerRenderData.name}</h3>${table}</div>`;
                    });

                    return `
                        <div>
                            <h1>${section.headerRenderData.name}</h1>
                            ${menuSubSections.join('')}
                        </div>
                    `;
                });

                blob = new Blob(['\ufeff', css + html], { type: 'application/msword' });

                url = URL.createObjectURL(blob);

                link = document.createElement('A');

                link.href = url;

                link.download = this.selectedMenu.title + '.doc';

                document.body.appendChild(link);

                if (navigator.msSaveOrOpenBlob ) navigator.msSaveOrOpenBlob( blob, 'Document.doc'); // IE10-11
                else link.click();  // other browsers

                document.body.removeChild(link);
        },
		publishMenu(menuId) {
			this.$store.dispatch("publishMenu", { menuId });
		},
		clearPublishingResult() {
			this.$store.dispatch("clearPublishingResult");
        },
	},
	watch: {
		selectedMenuId() {
            this.search = "";
		},
	},
};
</script>