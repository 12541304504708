<style>
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import "./assets/styles/forms.css";
@import "./assets/styles/navbar.css";
@import "./assets/styles/phone-preview.css";
@import "./assets/styles/bootstrap-grid.min.css";

body {
  font: 16px/1.5 "Roboto", sans-serif;
  margin: 0px;
  background-color: #f7f7f7;
  overflow: auto;
}

input,
textarea {
  font-family: "Roboto", sans-serif;
}

textarea {
  resize: vertical;
}

h1 {
  font-size: 27px;
  font-weight: 400;
  color: #141414;
}

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #141414;
  margin-top: 8px;
  margin-bottom: 8px;
}

h3 {
  font-size: 12px;
  font-weight: 500;
  color: #777777;
  letter-spacing: 1px;
  margin-top: 4px;
  margin-bottom: 4px;
}

h4 {
  font-size: 14px;
  font-weight: 300;
  color: #757575;
  font-style: italic;
}

p {
  font-size: 16px;
  font-weight: 300;
  color: #757575;
  margin-top: 4px;
  margin-bottom: 4px;
}

a,
button {
  outline: none;
}

.btn {
  font-family: "Roboto", sans-serif;
  padding: 6px 22px;
  color: #adadad;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 3px;
  border-radius: 20px;
  background-color: transparent;
  cursor: pointer;
  border: 0;
}

.btn.btn-filled {
  color: #fff;
  background-color: grey;
}

.btn.btn-filled.btn-success {
  background-color: #6be29d;
}

.btn.btn-filled.btn-cancel {
  background-color: red;
}

div.debug-panel {
  position: fixed;
  right: 10px;
  bottom: 10px;
  border-radius: 10px;
  background-color: white;
  border-color: #b4b4b4;
  padding: 20px;
  z-index: 10001;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

div.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0;
}

div.loading-wrapper > * {
  margin-bottom: 30px;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(0, -100%);
}
.slide-leave-to {
  transform: translate(0, 100%);
}
</style>

<template>
  <div id="app">
    <div v-if="!loading">
      <router-view />
    </div>

    <transition name="slide">
      <div v-if="loading" class="loading-wrapper">
        <div style="text-align: center">
          <img src="./assets/img/logo_menu@2x.png" />
          <br />
          <span> Inicializando DeAutor </span>
        </div>
        <i class="fas fa-circle-notch fa-spin fa-3x"></i>
      </div>
    </transition>

    <div class="debug-panel" v-if="showDebug">
      <small>
        <table style="width: 100%">
          <tr>
            <td style="font-weight: bold">User ID</td>
            <td style="text-align: right">{{ userId }}</td>
          </tr>
          <tr>
            <td style="font-weight: bold">Restaurant ID</td>
            <td style="text-align: right">{{ restaurantId }}</td>
          </tr>
          <tr>
            <td style="font-weight: bold">Admin</td>
            <td style="text-align: right">{{ isAdmin }}</td>
          </tr>
          <tr>
            <td style="font-weight: bold">UserTokenReady</td>
            <td style="text-align: right">{{ userTokenReady }}</td>
          </tr>
          <tr>
            <td style="font-weight: bold">Account ready</td>
            <td style="text-align: right">{{ accountIsReady }}</td>
          </tr>
          <tr>
            <td style="font-weight: bold">Loading account</td>
            <td style="text-align: right">{{ loadingAccount }}</td>
          </tr>
          <tr>
            <td style="font-weight: bold">Fetching restaurant</td>
            <td style="text-align: right">{{ fetchingRestaurant }}</td>
          </tr>
          <tr>
            <td style="font-weight: bold">Fetching menu</td>
            <td style="text-align: right">{{ fetchingMenu }}</td>
          </tr>
        </table>
      </small>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    accountIsReady() {
      return this.$store.getters["accountIsReady"];
    },
    showDebug() {
      //   return this.isAdmin;
      return false;
    },
    userId() {
      return this.$store.state.account.id;
    },
    userRestaurantId() {
      return this.$store.state.account.restaurantId;
    },
    restaurantId() {
      return this.$store.state.restaurant.id || "-";
    },
    isAdmin() {
      return this.$store.state.account.isAdminUser;
    },
    userTokenReady() {
      return this.$store.state.userTokenReady;
    },
    fetchingRestaurant() {
      return this.$store.state.restaurant.fetching;
    },
    fetchingMenu() {
      return this.$store.state.menu.fetching;
    },
    loadingAccount() {
      return this.$store.state.account.loading;
    },
    loading() {
      return (
        !this.userTokenReady ||
        this.fetchingRestaurant ||
        this.fetchingMenu ||
        this.loadingAccount
      );
    },
  },
  watch: {
    userRestaurantId() {
      this.$store.dispatch("loadRestaurant");
    },
  },
};
</script>