import Vue from 'vue';
import { product as emptyProduct } from '../../models/empty';

function setNested(schema, path, value) {
    var pList = path.split('.');
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i];
        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem];
    }

    schema[pList[len-1]] = value;
}

export default {
    state: {
        fetching: false,
        menus: {},
        updatingProduct: false,
        products: [],
        publishing: "off",
        publishedErrorMessage : "",
        fatalError: {
            context: '',
            exception: null
        },
    },
    mutations: {
        SET_FETCHING_MENUS(state, value) {
            console.debug('Menu/mutations/SET_FETCHING_MENUS\nvalue:', value);
            state.fetching = value;
        },
        SET_MENU(state, { menuId, menuData }) {
            console.debug('Menu/mutations/SET_MENU\nmenuId:', menuId, 'menuData:', menuData);
            Vue.set(state.menus, menuId, menuData);
        },
        ADD_PRODUCT(state, product) {
            console.debug('Menu/mutations/ADD_PRODUCT\nproduct:', product);
            if (product.isActive == undefined)
            {
                product.isActive = true;
            }

            if (product.isNew == "true")
            {
                product.isNew = true;
            }
            if (product.isNew == "false")
            {
                product.isNew = false;
            }

            if (product.isPlatoDelDia == "true")
            {
                product.isPlatoDelDia = true;
            }
            if (product.isPlatoDelDia == "false")
            {
                product.isPlatoDelDia = false;
            }

            state.products.push(product);
        },
        ADD_PRODUCT_TO_MENU(state, { menuId, sectionIndex, subsectionIndex, productId }) {
            console.debug('Menu/mutations/ADD_PRODUCT_TO_MENU\nmenuId:', menuId, 'sectionIndex:', sectionIndex, 'subsectionIndex:', subsectionIndex, 'productId:', productId);
            state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[subsectionIndex].products.push(productId);
        },
        UPDATE_PRODUCT(state, { productId, attribute, value }) {
            console.debug('Menu/mutations/UPDATE_PRODUCT\nproductId:', productId, ' attribute:', attribute, ' value:', value);
            state.products[state.products.findIndex(product => product.id == productId)][attribute] = value;
        },
        DELETE_PRODUCT(state, { menuId, productId }) {
            console.debug('Menu/mutations/DELETE_PRODUCT\nproductId:', productId);
            state.menus[menuId].menuMainSections.forEach((mainSection) => 
                mainSection.menuSubSections.forEach((subSection) => {
                    subSection.products = subSection.products.filter((subSectionProductId) => subSectionProductId != productId);
                })
            );
            state.products = state.products.filter(
				(product) => product.id != productId
			)
        },
        ADD_SUBTITLE_TO_MENU(state, { menuId, sectionIndex, subsectionIndex, subtitleData }) {
            console.debug('Menu/mutations/ADD_SUBTITLE_TO_MENU\nmenuId:', menuId, 'sectionIndex:', sectionIndex, 'subsectionIndex:', subsectionIndex, 'subtitleData:', subtitleData);
            state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[subsectionIndex].subtitles.push(subtitleData);
        },
        UPDATE_SUBTITLE(state, { menuId, sectionIndex, subsectionIndex, subtitle, attribute, value }) {
            console.debug('Menu/mutations/UPDATE_SUBTITLE\nmenuId:', menuId, 'sectionIndex:', 'subsectionIndex:', subsectionIndex, sectionIndex, 'subtitle:', subtitle, 'attribute:', attribute, 'value:', value);
            let subtitleElement = state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[subsectionIndex].subtitles.find(element => element.positionIndex == subtitle.positionIndex);
            subtitleElement[attribute] = value;
        },
        UPDATE_SUBTITLE_INDEX(state, { menuId, sectionIndex, subsectionIndex, subtitle, newIndex }) {
            console.debug('Menu/mutations/UPDATE_SUBTITLE_INDEX\nmenuId:', menuId, 'sectionIndex:', 'subsectionIndex:', subsectionIndex, sectionIndex, 'subtitle:', subtitle);
            let subtitleElement = state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[subsectionIndex].subtitles.find(element => element.positionIndex == subtitle.positionIndex && element.subtitleName == subtitle.subtitleName);
            subtitleElement.positionIndex = newIndex;
        },
        DELETE_SUBTITLE(state, { menuId, sectionIndex, subsectionIndex, subtitle }) {
            console.debug('Menu/mutations/DELETE_SUBTITLE\nmenuId:', menuId, 'sectionIndex:', sectionIndex, 'subsectionIndex:', subsectionIndex, 'subtitle:', subtitle);
            let index = state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[subsectionIndex].subtitles.findIndex(element => element.positionIndex == subtitle.positionIndex);
            state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[subsectionIndex].subtitles.splice(index, 1);
        },
        ADD_SUBSECTION_TO_MENU(state, { menuId, sectionIndex, subSectionData }) {
            console.debug('Menu/mutations/ADD_SUBSECTION_TO_MENU\nmenuId:', menuId, 'sectionIndex:', sectionIndex, 'subSectionData:', subSectionData);
            state.menus[menuId].menuMainSections[sectionIndex].menuSubSections.push(subSectionData);
        },
        UPDATE_SUBSECTION(state, { menuId, sectionIndex, subsectionIndex, attribute, value }) {
            console.debug('Menu/mutations/UPDATE_SUBSECTION\nmenuId:', menuId, 'sectionIndex:', sectionIndex, 'subsectionIndex:', subsectionIndex, 'attribute:', attribute, 'value:', value);
            console.log(state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[subsectionIndex]);
            setNested(state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[subsectionIndex], attribute, value);
        },
        DELETE_SUBSECTION(state, { menuId, sectionIndex, subsectionIndex }) {
            console.debug('Menu/mutations/DELETE_SUBSECTION\nmenuId:', menuId, 'sectionIndex:', sectionIndex, 'subsectionIndex:', subsectionIndex);
            state.menus[menuId].menuMainSections[sectionIndex].menuSubSections.splice(subsectionIndex, 1);
        },
        SET_UPDATING_PRODUCT(state, value) {
            console.debug('Menu/mutations/SET_UPDATING_PRODUCT\nvalue:', value);
            state.updatingProduct = value;
        },
        SET_PUBLISHING(state, value) {
            console.debug('Menu/mutations/SET_PUBLISHING\nvalue:', value);
            if (value != "ok" && value != "error" && value != "off" && value != "" && value != "running")
            {
                state.publishing = "error";
                state.publishedErrorMessage = value;
            }
            else
            {
                state.publishing = value;
                state.publishedErrorMessage = "";
            }
        },
        SET_FATAL_ERROR(state, { context, exception }) {
            console.debug('Menu/mutations/SET_FATAL_ERROR\ncontext:', context, 'exception:', exception);
            state.fatalError = { context, exception };
        }
    },
    actions: {
        getMenus({ rootState, commit }) {
            console.debug('Menu/actions/getMenus');

            const db = window.firebase.firestore();
            const menusCollection = db.collection("restaurants").doc(rootState.restaurant.id).collection('menus');

            return new Promise((resolve) => {
                commit('SET_FETCHING_MENUS', true);

                // Gets all the restaurant's menu.
                menusCollection.get().then(querySnapshot => {
                    let promises = [];
                    // Iterates each menu.
                    querySnapshot.forEach(menu => {
                        // Saves the data of the menu.
                        commit('SET_MENU', { menuId: menu.id, menuData: menu.data() });
                        // Gets each product of this menu and adds the promise to the array of promises.
                        for (const section of menu.data().menuMainSections)
                            for (const subsection of section.menuSubSections)
                                for (const productId of subsection.products)
                                    promises.push(db.collection("products").doc(productId).get());
                    });
                    // When all the promises in the array are ready, adds each product to the products list
                    Promise.all(promises.map(p => p.catch(e => e)))
                        .then(products => {
                            products.forEach(product => commit('ADD_PRODUCT', { id: product.id, ...product.data() }));
                            commit('SET_FETCHING_MENUS', false);
                            resolve();
                        });
                });
            });
        },
        updateMenuMainSections({ rootState, state }, { menuId }) {
            return window.firebase.firestore().collection("restaurants").doc(rootState.restaurant.id).collection('menus').doc(menuId).update({
                menuMainSections: state.menus[menuId].menuMainSections
            });
        },
        /**
         * Products
         */
        addProductToMenu({ dispatch, commit, state, rootState }, { menuId, sectionIndex, subsectionIndex }) {
            console.debug('Menu/actions/addProductToMenu\nmenuId:', menuId, 'sectionIndex:', sectionIndex, 'subsectionIndex:', subsectionIndex);

            //get max position index
            const subsection = state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[subsectionIndex];
            var maxIndex = 0;
            subsection.products.forEach((productId) => {
                const prodFound = state.products.find(prod => prod.id == productId);
                if (prodFound != undefined) maxIndex = Math.max(prodFound.positionIndex, maxIndex);
            });
            subsection.subtitles.forEach((subtitle) => {
                maxIndex = Math.max(subtitle.positionIndex, maxIndex);
            });

            // Gets the firestore reference.
            const db = window.firebase.firestore();
            // Creates the product data object.
            const productData = {
                ...emptyProduct,
                restaurantId: rootState.restaurant.id,
                productType: state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[subsectionIndex].isSeccionBebidas ? 2 : 1,
                positionIndex: maxIndex+1
            };
            // Sets the loading state
            commit('SET_UPDATING_PRODUCT', true);
            // Creates the new product on firestore.
            db.collection('products').add(productData)
                // Updates the state.
                .then(document => {
                    // Adds the new product to the products list.
                    commit('ADD_PRODUCT', { id: document.id, ...productData });
                    // Adds the new product id to the menu and saves it on firestore.
                    commit('ADD_PRODUCT_TO_MENU', { menuId, sectionIndex, subsectionIndex, productId: document.id });
                    // Updates the menu sections with the new data.
                    dispatch('updateMenuMainSections', { menuId }).catch(exception => commit('SET_FATAL_ERROR', { context: 'Agregar un producto al menú', exception }));
                })
                .catch(exception => commit('SET_FATAL_ERROR', { context: 'Agregar un producto al menú', exception }))
                // Removes the loading state.
                .finally(() => commit('SET_UPDATING_PRODUCT', false));
        },
        updateProduct({ commit }, { productId, attribute, value }) {
            console.debug('Menu/actions/updateProduct\nproductId:', productId, 'attribute:', attribute, 'value:', value);
            // Sets the loading state
            commit('SET_UPDATING_PRODUCT', true);
            window.firebase.firestore().collection('products')
                // Updates the product on firestore
                .doc(productId).update({ [attribute]: value })
                // Updates the product in the state
                .then(() => commit('UPDATE_PRODUCT', { productId, attribute, value }))
                .catch(exception => commit('SET_FATAL_ERROR', { context: 'Actualizar un producto', exception }))
                // Removes the loading state.
                .finally(() => commit('SET_UPDATING_PRODUCT', false));
        },
        deleteProduct({ dispatch, commit }, { menuId, productId }) {
            console.debug('Menu/actions/deleteProduct\nproductId:', productId);
            const db = window.firebase.firestore();
            // Sets the loading state
            commit('SET_UPDATING_PRODUCT', true);
            // deletes the product in the state
            commit('DELETE_PRODUCT', { menuId, productId });

            dispatch('updateMenuMainSections', { menuId }).then(() => {
                db.collection('products').doc(productId).delete()
                    .catch(exception => commit('SET_FATAL_ERROR', { context: 'Eliminar un producto', exception }))
                    .finally(() => commit('SET_UPDATING_PRODUCT', false));
            })
            .catch(exception => commit('SET_FATAL_ERROR', { context: 'Eliminar un producto', exception }));
        },
        /**
         * Subtitles
         */
        addSubtitleToMenu({ dispatch, commit, state }, { menuId, sectionIndex, subsectionIndex }) {
            console.debug('Menu/actions/addSubtitleToMenu\nmenuId:', menuId, 'sectionIndex:', sectionIndex, 'subsectionIndex:', subsectionIndex);

            //get max position index
            const subsection = state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[subsectionIndex];
            var maxIndex = 0;
            subsection.products.forEach((productId) => {
                const prodFound = state.products.find(prod => prod.id == productId);
                if (prodFound != undefined) maxIndex = Math.max(prodFound.positionIndex, maxIndex);
            });
            subsection.subtitles.forEach((subtitle) => {
                maxIndex = Math.max(subtitle.positionIndex, maxIndex);
            });

            // Creates the subtitle data object.
            const subtitleData = { positionIndex: maxIndex + 1, type: 1, subtitleName: 'Texto del subtítulo' };

            // Sets the loading state
            commit('SET_UPDATING_PRODUCT', true);

            // Adds the new subtitle to the menu and saves it on firestore.
            commit('ADD_SUBTITLE_TO_MENU', { menuId, sectionIndex, subsectionIndex, subtitleData });
            dispatch('updateMenuMainSections', { menuId })
                .catch(exception => commit('SET_FATAL_ERROR', { context: 'Agregar un subtítulo al menú', exception }))
                .finally(() => commit('SET_UPDATING_PRODUCT', false));
        },
        updateSubtitle({ dispatch, commit }, { menuId, sectionIndex, subsectionIndex, subtitle, attribute, value }) {
            console.debug('Menu/actions/updateSubtitle\nmenuId:', menuId, 'sectionIndex:', sectionIndex, 'subsectionIndex:', subsectionIndex, 'subtitle:', subtitle, 'attribute:', attribute, 'value:', value);
            commit('SET_UPDATING_PRODUCT', true);
            commit('UPDATE_SUBTITLE', { menuId, sectionIndex, subsectionIndex, subtitle, attribute, value });
            dispatch('updateMenuMainSections', { menuId })
                .catch(exception => commit('SET_FATAL_ERROR', { context: 'Actualizar un subtítulo', exception }))
                .finally(() => commit('SET_UPDATING_PRODUCT', false));
        },
        deleteSubtitle({ dispatch, commit }, { menuId, sectionIndex, subsectionIndex, subtitle }) {
            console.debug('Menu/actions/updateSubtitle\nmenuId:', menuId, 'sectionIndex:', sectionIndex, 'subsectionIndex:', subsectionIndex, 'subtitle:', subtitle);
            commit('SET_UPDATING_PRODUCT', true);
            commit('DELETE_SUBTITLE', { menuId, sectionIndex, subsectionIndex, subtitle });
            dispatch('updateMenuMainSections', { menuId })
                .catch(exception => commit('SET_FATAL_ERROR', { context: 'Eliminar un subtítulo', exception }))
                .finally(() => commit('SET_UPDATING_PRODUCT', false));
        },
        /**
         * Subsections 
         */
        addSubSectionToMenu({ dispatch, commit, state }, { menuId, sectionIndex }) {
            const section = state.menus[menuId].menuMainSections[sectionIndex];
            var maxIndex = 0;
            section.menuSubSections.forEach((subsection) => {
                maxIndex = Math.max(subsection.positionIndex, maxIndex);
            });

            const subSectionData = {
                positionIndex: maxIndex+1,
                isSeccionBebidas: section.isSeccionBebidas ?? false,
                products: [],
                subtitles: [],
                headerRenderData: {
                    name: 'Nuevo Grupo',
                    visibleTitle: 'Nuevo Grupo',
                    bottomComments: "",
                    hasAskForRecommendation: "",
                    hasNavigation: false,
                    headerTitle: 0,
                    imageUrl: {
                        name: new Date().getTime(),
                        image2: "",
                        image1: "",
                        image3: ""
                    },
                }
            };

            commit('SET_UPDATING_PRODUCT', true);
            commit('ADD_SUBSECTION_TO_MENU', { menuId, sectionIndex, subSectionData });
            dispatch('updateMenuMainSections', { menuId })
                .catch(exception => commit('SET_FATAL_ERROR', { context: 'Agregar un grupo al menú', exception }))
                .finally(() => commit('SET_UPDATING_PRODUCT', false));
        },
        updateSubSection({ dispatch, commit }, { menuId, sectionIndex, subsectionIndex, attribute, value }) {
            console.debug('Menu/actions/updateSubSection\nmenuId:', menuId, 'sectionIndex', sectionIndex, 'subsectionIndex', subsectionIndex, 'attribute', attribute, 'value', value);
            
            commit('SET_UPDATING_PRODUCT', true);
            
            commit('UPDATE_SUBSECTION', { menuId, sectionIndex, subsectionIndex, attribute, value });
            if(attribute == 'headerRenderData.name') commit('UPDATE_SUBSECTION', { menuId, sectionIndex, subsectionIndex, attribute: 'headerRenderData.visibleTitle', value });
            
            dispatch('updateMenuMainSections', { menuId })
                .catch(exception => commit('SET_FATAL_ERROR', { context: 'Actualizar un grupo', exception }))
                .finally(() => commit('SET_UPDATING_PRODUCT', false));
        },
        deleteSubSection({ dispatch, commit }, { menuId, sectionIndex, subsectionIndex }) {
            console.debug('Menu/actions/updateSubtitle\nmenuId:', menuId, 'sectionIndex:', sectionIndex, 'subsectionIndex:', subsectionIndex);
            
            commit('SET_UPDATING_PRODUCT', true);
            
            // Al obligar al usuario a eliminar manualmente todos los items antes de eliminar la subsección, esto no es necesario.
            // const db = window.firebase.firestore();
            // state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[subsectionIndex].products.forEach((productId) => {
            //     db.collection('products').doc(productId).delete();
            // });

            commit('DELETE_SUBSECTION', { menuId, sectionIndex, subsectionIndex });
            dispatch('updateMenuMainSections', { menuId })
                .catch(exception => commit('SET_FATAL_ERROR', { context: 'Eliminar un grupo', exception }))
                .finally(() => commit('SET_UPDATING_PRODUCT', false));
        },
        changeSubsectionIndex({ dispatch, commit, state }, { menuId, sectionIndex, subsectionIndex, otherSubsectionIndex }) {
            console.debug('Menu/actions/changeSubsectionIndex\n', 'menuId', menuId, 'sectionIndex', sectionIndex, 'subsectionIndex', subsectionIndex, 'otherSubsectionIndex', otherSubsectionIndex);
            // Sets the loading state
            commit('SET_UPDATING_PRODUCT', true);

            let originalIndex = state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[subsectionIndex].positionIndex;
            let newIndex = state.menus[menuId].menuMainSections[sectionIndex].menuSubSections[otherSubsectionIndex].positionIndex;

            commit('UPDATE_SUBSECTION', { menuId, sectionIndex, subsectionIndex, attribute: 'positionIndex', value: newIndex });
            commit('UPDATE_SUBSECTION', { menuId, sectionIndex, subsectionIndex: otherSubsectionIndex, attribute: 'positionIndex', value: originalIndex });

            dispatch('updateMenuMainSections', { menuId }).finally(() => commit('SET_UPDATING_PRODUCT', false));
        },
        changeProductsAndSubtitlesIndex({ dispatch, commit }, { menuId, sectionIndex, subsectionIndex, product, other }) {
            console.debug('Menu/actions/changeIndex\nproductId:', product.id);

            return new Promise((resolve) => {
                // Sets the loading state
                commit('SET_UPDATING_PRODUCT', true);
    
                let originalIndex = product.positionIndex;
                let newIndex = other.positionIndex;
    
                if (product.type == 0)
                {
                    commit('UPDATE_PRODUCT', { productId: product.id, attribute: 'positionIndex', value: newIndex });
                }
                else
                {
                    commit('UPDATE_SUBTITLE_INDEX', { menuId, sectionIndex, subsectionIndex, subtitle: product, newIndex });
                }
    
                if (other.type == 0)
                {
                    commit('UPDATE_PRODUCT', { productId: other.id, attribute: 'positionIndex', value: originalIndex });
                }
                else
                {
                    commit('UPDATE_SUBTITLE_INDEX', { menuId, sectionIndex, subsectionIndex, subtitle: other, newIndex: originalIndex });
                }
    
                if (product.type == 0)
                {
                    window.firebase.firestore().collection('products')
                        .doc(product.id).update({ positionIndex: newIndex })
                        .then(() => {
                            if (other.type == 1)
                            { 
                                dispatch('updateMenuMainSections', { menuId }).finally(() => {
                                    commit('SET_UPDATING_PRODUCT', false);
                                    resolve();
                                });
                            }
                            else
                            {
                                window.firebase.firestore().collection('products')
                                .doc(other.id).update({ positionIndex: originalIndex })
                                .then(() => {
                                    commit('SET_UPDATING_PRODUCT', false);
                                    resolve();
                                });
                            }
                        });
                }
                else
                {
                    dispatch('updateMenuMainSections', { menuId })
                        .then(() => {
                            // Solo es necesario actualizar si hay producto, si ambos son subtítulos se actualizan juntos cuando se llamo al updateMenuMainSections arriba.
                            if (other.type == 0)
                            {
                                window.firebase.firestore().collection('products')
                                .doc(other.id).update({ positionIndex: originalIndex })
                                .then(() => {
                                    commit('SET_UPDATING_PRODUCT', false);
                                    resolve();
                                });
                            }
                        })
                        .finally(() => commit('SET_UPDATING_PRODUCT', false));
                }
            });
        },
        publishMenu({ commit, rootState }, { menuId }) {
            console.debug('Menu/actions/publishMenu menuId:', menuId);
            // Sets the loading state
            commit('SET_PUBLISHING', "running");
            
            var publishMenuFunction = window.firebase.functions().httpsCallable('publishMenu');
            const params = { restoId: rootState.restaurant.id, menuId: menuId };

            publishMenuFunction(params)
                .then((response) => {
                    console.debug("Comandas/actions/menuPublished\nfirebase response:", response);
                    if (response.data == undefined)
                    {
                        commit('SET_PUBLISHING', "ok");
                    }
                    else
                    {
                        commit('SET_PUBLISHING', response.data);
                    }
                    setTimeout(() => {
                        commit('SET_PUBLISHING', "off");
                      }, 5000)
                })
                .catch((error) => {
                    console.debug("Comandas/actions/menuPublished\nfirebase error:", error);
                    commit('SET_PUBLISHING', "error");
                    setTimeout(() => {
                        commit('SET_PUBLISHING', "off");
                      }, 5000)
                });
        },
        clearPublishingResult({commit}) {
            commit('SET_PUBLISHING', "off");
        },
    },
    getters: {
        menusSelect(state) {
            let options = [];

            for (const [menuId, menuData] of Object.entries(state.menus))
                options.push({ id: menuId, name: menuData.title });

            return options;
        },
        menusList(state) {
            let menus = {};

            for (const [menuId, menuData] of Object.entries(state.menus)) {
                menus[menuId] = {
                    title: menuData.title,
                    menuMainSections: menuData.menuMainSections.map((section, index) => {
                        let subsections = section.menuSubSections.sort((a, b) => a.positionIndex - b.positionIndex).map(subsection => {
                            let elements = [];

                            for(const product of subsection.products) 
                                elements.push({ type: 0, ...state.products.find(({ id }) => id == product) });

                            for(let i = 0; i < subsection.subtitles.length; i++)
                                elements.push({ id: i, type: 1, ...subsection.subtitles[i] });
                            
                            elements = elements.sort((a, b) => a.positionIndex - b.positionIndex);
                            
                            for(let i = 0; i < elements.length; i++)
                            { 
                                elements[i].prevElement = i != 0 ? elements[i - 1] : undefined;
                                elements[i].nextElement = (i != elements.length - 1) ? elements[i + 1] : undefined;
                            }
                            
                            return {
                                id: index,
                                headerRenderData: {
                                    name: subsection.headerRenderData.name,
                                },
                                positionIndex: subsection.positionIndex,
                                isSeccionBebidas: subsection.isSeccionBebidas,
                                productsAndSubtitles: elements
                                // products: subsection.products.map(product => {let productToReturn = state.products.find(({ id }) => id == product); productToReturn.type = 0; return productToReturn;}),
                                // subtitles: subsection.subtitles.map((subtitle, index) => {return {index, type: 1, id: 's' + subtitle.subtitleName, subtitleName: subtitle.subtitleName, positionIndex: subtitle.positionIndex}}),
                            }
                        });

                        for(let i = 0; i < subsections.length; i++)
                        { 
                            subsections[i].prevElement = i != 0 ? subsections[i - 1] : undefined;
                            subsections[i].nextElement = (i != subsections.length - 1) ? subsections[i + 1] :undefined;
                        }

                        return {
                            headerRenderData: {
                                name: section.headerRenderData.name
                            },
                            canEditSubSections: section.canEditSubSections,
                            menuSubSections: subsections
                        }
                    })
                };
            }

            return menus;
        }
    }
}