<style scoped>
div.wrapper {
	padding: 10px 30px;
}

div.wrapper > div.events {
    padding-left: 33px;
    width: 60%;
}
</style>

<template>
	<div class="wrapper">
		<h1>Historico de notificaciones</h1>

		<div class="events">
			<historical-card
				v-for="event in events"
				:key="event.id"
				:time-stamp="event.timeStamp"
				:type="event.type"
                :title="event.tableName"
			>
				<template v-if="event.type == 'ORDER_PLACED'">
					<div v-for="item in event.data" :key="item.productId">
						<span>{{ item.quantity }}x {{ item.productName }}</span>
					</div>
				</template>
			</historical-card>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		events() {
			return this.$store.getters["eventsList"];
		},
	},
};
</script>