import { ActionContext } from "vuex";
import { AccountState } from "../states/AccountState";
import { RootState } from "../states/RootState";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { PostsState } from "../states/PostsState";
import { Post, PostFields } from "models";
import moment from "moment";

export default {
    state: <PostsState>{
        savingPost: false,
        posts: [],
    },
    mutations: {
        SET_SAVING_POSTS(state: PostsState, data: { saving: boolean }) {
            console.debug('Posts/mutations/SET_SAVING_POSTS\n', data);
            state.savingPost = data.saving;
        },
        SET_POSTS(state: PostsState, data: { posts: Array<Post> }) {
            console.debug('Posts/mutations/SET_POSTS\n', data);
            state.posts = data.posts;
        }
    },
    actions: {
        getPosts(context: ActionContext<PostsState, RootState>) {
            console.debug('Posts/actions/getPosts');

            firebase.firestore().collection('posts').onSnapshot((snapshot) => {
                const posts = snapshot.docs.map((document) => new Post(document.id, document.data() as PostFields));

                context.commit('SET_POSTS', { posts });
            });
        },
        savePost(context: ActionContext<PostsState, RootState>, data: { post: Post, imageBase64: string }) {
            console.debug('Posts/actions/savePost\n', data);

            context.commit('SET_SAVING_POSTS', { saving: true });

            return new Promise<void>((resolve) => {
                const callable = firebase.functions().httpsCallable('savePostForAdmin');

                callable({ post: data.post.serialize(), imageBase64: data.imageBase64 }).finally(() => {
                    context.commit('SET_SAVING_POSTS', { saving: false });
                    resolve();
                });
            });
        },
        getPostById(context: ActionContext<PostsState, RootState>, data: { postId: string }) {
            return context.state.posts.find((post) => post.id === data.postId)?.clone();
        }
    },
    getters: {
    }
};
