<style scoped>
div.card {
  border: solid 1px;
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 8px;
  margin-bottom: 30px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  border: none;
  background-color: #e8e8e8;
  min-height: 100px;
}

div.card > div.icon {
  background-color: #adadad;
  border-radius: 33px;
  padding: 15px;
  position: absolute;
  width: 66px;
  height: 66px;
  left: -33px;
  top: calc(50% - 33px);
  box-sizing: border-box;
}

div.card > div.content {
  margin-left: 33px;
  font-size: 16px;
  font-weight: 300;
  color: #757575;
}

div.card > div.content > div.subtitle {
  text-transform: uppercase;
  color: #777777;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 5px;
  display: flex;
}

div.card > div.content > div.subtitle > span {
  margin-left: auto;
  font-style: italic;
  color: #b4b4b4;
  font-weight: 300;
  letter-spacing: initial;
}

div.card > div.content > h2.title {
  text-transform: uppercase;
}
</style>

<template>
  <div class="card">
    <div class="icon">
      <img v-if="!waiterImage" src="../assets/img/comanda.svg" />
      <img v-else src="../assets/img/mozo.svg" style="padding-left: 5px" />
    </div>
    <div class="content">
      <div class="subtitle">
        {{ subtitle }}
        <span>{{ fromNow }}</span>
      </div>

      <h2 class="title">{{ title }}</h2>

      <slot />
    </div>
    <div class="historica_icono">
      <div class="comanda_img_historica"></div>
    </div>
  </div>
</template>

<script>
import { moment } from "moment";

export default {
  props: {
    timeStamp: { type: Date, required: true },
    title: { type: String, required: false, default: "" },
    type: { type: String, required: true },
  },
  computed: {
    waiterImage() {
      return this.type == "WAITER_CALLED" || this.type == "WAITER_CONFIRMED";
    },
    subtitle() {
      switch (this.type) {
        case "ORDER_PLACED":
          return "Nueva orden";

        case "TABLE_OPENED":
          return "Mesa abierta";

        case "ORDER_CONFIRMED":
          return "Orden confirmada";

        case "WAITER_CALLED":
          return "Llama a mozo";

        case "WAITER_CONFIRMED":
          return "Mozo confirmada llamado";

        case "CLOSE_CALLED":
          return "Pide la cuenta";

        case "CLOSE_CONFIRMED":
          return "Cuenta confirmada";

        case "USER_ADDED":
          return "Nuevo usuario";

        default:
          return "";
      }
    },
    fromNow() {
      return moment(this.timeStamp).fromNow();
    },
  },
};
</script>