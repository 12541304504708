




import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  props: {
    value: { type: Date, required: true },
  },
  computed: {
    formatedDate(): string {
      return moment(this.value).format("YYYY-MM-DD");
    },
  },
  methods: {
    inputDate(value: string): void {
      this.$emit("input", moment(value).toDate());
    },
  },
});
