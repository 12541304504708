import { ActionContext } from 'vuex';
import { Reservation, ReservationConfig, ReservationConfigFields, ReservationFields } from 'models';
import { RootState } from '../states/RootState';
import { ReservationsState } from '../states/ReservationsState';

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

function listenReservations(restaurantId: string, date: Date, onSnapshot: Function) {
    const ymd = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    const startRange = new Date(`${ymd} 00:00:00`);
    const endRange = new Date(`${ymd} 23:59:59`)

    const reservationsReference = firebase.firestore()
        .collection(`restaurants/${restaurantId}/reservations`)
        .where('date', '>=', startRange)
        .where('date', '<=', endRange);

    const unsuscriber = reservationsReference.onSnapshot((snapshot) => {
        const cancelledReservations: Array<Reservation> = [],
            arrivedReservations: Array<Reservation> = [],
            pendingReservations: Array<Reservation> = [];

        // Divide las reservas en pendientes, canceladas o completadas.
        snapshot.forEach((document) => {
            const reservation = new Reservation(document.id, document.data() as ReservationFields);

            if (reservation.fields.cancelledAt !== null) {
                cancelledReservations.push(reservation);
            }
            else if (reservation.fields.arrived !== null) {
                arrivedReservations.push(reservation);
            }
            else {
                pendingReservations.push(reservation);
            }
        });

        // Ordena los listados por horario ascendente.
        cancelledReservations.sort((a, b) => a.fields.date.getTime() - b.fields.date.getTime());
        arrivedReservations.sort((a, b) => a.fields.date.getTime() - b.fields.date.getTime());
        pendingReservations.sort((a, b) => a.fields.date.getTime() - b.fields.date.getTime());

        onSnapshot([...pendingReservations, ...arrivedReservations, ...cancelledReservations]);
    });

    return unsuscriber;
}

export default {
    state: <ReservationsState>{
        creatingReservation: false,
        loadingReservationsConfig: false,
        config: ReservationConfig.empty(),
        loadingReservationsId: [],
        todayReservations: [],
        otherReservations: [],
        otherReservationsDate: null,
        otherReservationsUnsuscriber: null,
        otherReservationsLoading: false,
    },
    mutations: {
        SET_CREATING_RESERVATION(state: ReservationsState, data: { loading: boolean }) {
            console.debug('Reservations/mutations/SET_CREATING_RESERVATION\n', data);
            state.creatingReservation = data.loading;
        },
        SET_RESERVATIONS_CONFIG_LOADING(state: ReservationsState, data: { loading: boolean }) {
            console.debug('Reservations/mutations/SET_RESERVATIONS_CONFIG_LOADING\n', data);
            state.loadingReservationsConfig = data.loading;
        },
        SET_RESERVATIONS_CONFIG(state: ReservationsState, data: { config: ReservationConfig }) {
            console.debug('Reservations/mutations/SET_RESERVATIONS_CONFIG\n', data);
            state.config = data.config;
        },
        SET_RESERVATION_LOADING(state: ReservationsState, data: { restaurantReservationId: string, loading: boolean }) {
            console.debug('Reservations/mutations/SET_RESERVATION_LOADING\n', data);
            if (data.loading) {
                state.loadingReservationsId.push(data.restaurantReservationId);
            }
            else if (state.loadingReservationsId.includes(data.restaurantReservationId)) {
                const index = state.loadingReservationsId.indexOf(data.restaurantReservationId);
                if (index > -1) {
                    state.loadingReservationsId.splice(index, 1);
                }
            }
        },
        SET_TODAY_RESERVATIONS(state: ReservationsState, data: { reservations: Array<Reservation> }) {
            console.debug('Reservations/mutations/SET_TODAY_RESERVATIONS\n', data);
            state.todayReservations = data.reservations;
        },
        SET_OTHER_RESERVATIONS(state: ReservationsState, data: { date: Date, reservations: Array<Reservation> }) {
            console.debug('Reservations/mutations/SET_OTHER_RESERVATIONS\n', data);
            state.otherReservationsDate = data.date;
            state.otherReservations = data.reservations;
        },
        SET_OTHER_RESERVATIONS_LISTENER_UNSUSCRIBER(state: ReservationsState, data: { unsuscriber: Function }) {
            console.debug('Reservations/mutations/SET_OTHER_RESERVATIONS_LISTENER_UNSUSCRIBER\n', data);
            state.otherReservationsUnsuscriber = data.unsuscriber;
        },
        SET_OTHER_RESERVATIONS_LOADING(state: ReservationsState, data: { loading: boolean }) {
            console.debug('Reservations/mutations/SET_OTHER_RESERVATIONS_LOADING\n', data);
            state.otherReservationsLoading = data.loading;
        }
    },
    actions: {
        initializeReservations(context: ActionContext<ReservationsState, RootState>) {
            console.debug('Reservations/actions/initializeReservations');

            // Configuración actual de las reservas.
            firebase.firestore().doc(`restaurants/${context.rootState.restaurant.id}/details/reservations`).get().then(document => {
                context.commit('SET_RESERVATIONS_CONFIG', {
                    config: document.exists ?
                        new ReservationConfig(document.id, document.data() as ReservationConfigFields) :
                        ReservationConfig.empty()
                });
            });

            // Se suscribe a las reservas del día de hoy para obtener actualizaciones en tiempo real.
            context.dispatch('listenTodayReservations');

            // Se suscribe a las reservas de mañana para obtener actualizaciones en tiempo real. Esta fecha puede ser cambiada luego por el usuario.
            const date = new Date();
            date.setDate(date.getDate() + 1);
            context.dispatch('listenOtherReservations', { date });
        },
        updateReservationsConfig(context: ActionContext<ReservationsState, RootState>, data: { config: ReservationConfig }) {
            console.debug('Reservations/actions/updateReservationsConfig');

            context.commit('SET_RESERVATIONS_CONFIG_LOADING', { loading: true });

            const callable = firebase.functions().httpsCallable('updateReservationsConfigFromRestaurant');

            // Crea una nueva promise que será resuelta cuando la configuración termine de ser updateada.
            return new Promise<void>((resolve) => {
                callable(data.config.serialize())
                    .then(
                        (result) => {
                            const data = JSON.parse(result.data);

                            context.commit('SET_RESERVATIONS_CONFIG', {
                                config: new ReservationConfig(data.id, data.fields)
                            });

                            resolve();
                        }
                    )
                    .finally(
                        () => context.commit('SET_RESERVATIONS_CONFIG_LOADING', { loading: false })
                    );
            });
        },
        listenTodayReservations(context: ActionContext<ReservationsState, RootState>) {
            console.debug('Reservations/actions/listenTodayReservations');

            listenReservations(
                context.rootState.restaurant.id, new Date(),
                (reservations: Array<Reservation>) => context.commit('SET_TODAY_RESERVATIONS', { reservations })
            );
        },
        listenOtherReservations(context: ActionContext<ReservationsState, RootState>, data: { date: Date }) {
            console.debug('Reservations/actions/listenOtherReservations\n:', data);

            context.commit('SET_OTHER_RESERVATIONS_LOADING', { loading: true });

            // Si ya se encontraba suscripto a otro listado de reservas, primero elimina dicha conexión.
            if (context.state.otherReservationsUnsuscriber != null) {
                console.debug('Reservations/actions/listenOtherReservations unsuscribing previous listener');
                context.state.otherReservationsUnsuscriber();
            }

            const unsuscriber = listenReservations(
                context.rootState.restaurant.id,
                data.date,
                (reservations: Array<Reservation>) => {
                    context.commit('SET_OTHER_RESERVATIONS', { date: data.date, reservations });
                    context.commit('SET_OTHER_RESERVATIONS_LOADING', { loading: false });
                }
            );

            context.commit('SET_OTHER_RESERVATIONS_LISTENER_UNSUSCRIBER', { unsuscriber });
        },
        updateReservationArrival(context: ActionContext<ReservationsState, RootState>, data: { restaurantReservationId: string, arrived: boolean }) {
            console.debug('Reservations/actions/updateReservationArrival\n', data);

            const callable = firebase.functions().httpsCallable('updateReservationArrivalFromRestaurant');

            context.commit('SET_RESERVATION_LOADING', { restaurantReservationId: data.restaurantReservationId, loading: true });

            callable(data).finally(() => context.commit('SET_RESERVATION_LOADING', { restaurantReservationId: data.restaurantReservationId, loading: false }));
        },
        createReservation(
            context: ActionContext<ReservationsState, RootState>,
            data: { date: string, hour: string, people: number, place: string, name: string, phone: string, notes: string }
        ) {
            console.debug('Reservations/actions/createReservation\n', data);

            const callable = firebase.functions().httpsCallable('createReservationFromRestaurant');

            return new Promise<boolean>((resolve, reject) => {
                context.commit('SET_CREATING_RESERVATION', { loading: true });

                callable(data)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                    .finally(() => context.commit('SET_CREATING_RESERVATION', { loading: false }));
            });

        },
        cancelReservation(context: ActionContext<ReservationsState, RootState>, data: { restaurantReservationId: string, requestedByClient: boolean }) {
            console.debug('Reservations/actions/cancelReservation\n', data);

            const callable = firebase.functions().httpsCallable('cancelReservationFromRestaurant');

            context.commit('SET_RESERVATION_LOADING', { restaurantReservationId: data.restaurantReservationId, loading: true });

            callable(data).finally(() => context.commit('SET_RESERVATION_LOADING', { restaurantReservationId: data.restaurantReservationId, loading: false }));
        }
    },
};