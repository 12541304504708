export const product = {
    restaurantId: null,
    positionIndex: 0,
    productName: "Nombre del Producto",
    isInLunch: false,
    isInDinner: false,
    isPlatoDelDia: false,
    expirationDate: null,
    productType: 1,
    shortDescription: "",
    longDescription: "",
    isActive: true,
    isNew: false,
    canShowDetails: false,
    portionSize: 1,
    portionDescription: "",
    isExtra: false,
    price: {
        lunch: 0,
        dinner: 0
    },
    detailsBackimageUrl: {
        name: "BACK.PNG",
        image2: "",
        image3: "",
        image1: ""
    },
    detailsFrontimageUrl: {
        name: "FRONT.PNG",
        image2: "",
        image1: "",
        image3: ""
    },
    menuImageUrl: {
        name: "MENU.PNG",
        image2: "",
        image3: "",
        image1: ""
    }
};