




























































































































import Vue from "vue";
import { ImageUrl } from "models";
import firebase from "firebase/app";
import "firebase/storage";

type DataType = {
  uploading: boolean;
  visible: boolean;
  file: File | null;
  dataUrl: string | null;
};

export default Vue.extend({
  props: {
    fileName: { type: String, required: true },
    storagePath: { type: String, required: true },
    value: { type: ImageUrl, required: false, default: null },
  },
  data(): DataType {
    return { uploading: false, visible: false, file: null, dataUrl: null };
  },
  computed: {
    validImage() {
      return this.value?.isValid ?? false;
    },
  },
  watch: {
    visible() {
      if (this.visible == false) {
        this.clearInput();
      }
    },
  },
  methods: {
    clearInput() {
      this.file = null;
      this.dataUrl = null;
      (this.$refs.fileInput as any).value = null;
    },
    cancel() {
      this.clearInput();
      this.visible = false;
    },
    onFileChange(e: any) {
      this.file = e.target.files[0];

      if (this.file && this.file.type.match(/image.*/)) {
        var reader = new FileReader();

        reader.onload = (event) =>
          (this.dataUrl = (event?.target?.result as string) ?? null);

        reader.readAsDataURL(this.file);
      }
    },
    dataURLToBlob(dataURL: string) {
      let BASE64_MARKER = ";base64,";

      if (dataURL.indexOf(BASE64_MARKER) == -1) {
        let parts = dataURL.split(",");
        let contentType = parts[0].split(":")[1];
        let raw = parts[1];

        return new Blob([raw], { type: contentType });
      }

      let parts = dataURL.split(BASE64_MARKER);
      let contentType = parts[0].split(":")[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;

      let uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    },
    resizeImage(image: HTMLImageElement, maxWidth: number): Blob {
      let width = image.width;
      let height = image.height;

      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }

      let canvas = document.createElement("canvas");

      canvas.width = width;
      canvas.height = height;

      canvas.getContext("2d")?.drawImage(image, 0, 0, width, height);

      return this.dataURLToBlob(canvas.toDataURL());
    },
    save() {
      // Valida que exista un archivo seleccionado.
      if (this.file === null) {
        alert("No hay imagen seleccionada para subir");
        return;
      }

      // Valida que el archivo seleccionado sea una imagen.
      if (!this.file.type.match(/image.*/)) {
        alert("El archivo seleccionado debe ser una imagen");
        return;
      }

      // Genera el nombre del nuevo archivo.
      const name = `${this.fileName}-${Date.now()}`;

      // Obtiene el storage de FireBase.
      const storage = firebase.storage().ref();

      // Empieza el proceso de carga.
      this.uploading = true;

      let reader = new FileReader();

      reader.onload = (readerEvent) => {
        if (!readerEvent || !readerEvent.target) {
          return;
        }

        let image = new Image();

        image.onload = () => {
          // Genera una Promise para cada tarea de subida (una por cada tamaño de imagen).
          const uploadTasks = [
            storage
              .child(`${this.storagePath}/${name}x1`)
              .put(this.resizeImage(image, 2000)),
            storage
              .child(`${this.storagePath}/${name}x2`)
              .put(this.resizeImage(image, 1000)),
            storage
              .child(`${this.storagePath}/${name}x3`)
              .put(this.resizeImage(image, 500)),
          ];

          // Espera a que todas las imagenes terminen de subir.
          Promise.all(uploadTasks)
            .then((snapshots) => {
              // Genera una Promise para cada tarea de obtención del link de descarga.
              const downloadTasks = [
                snapshots[0].ref.getDownloadURL(),
                snapshots[1].ref.getDownloadURL(),
                snapshots[2].ref.getDownloadURL(),
              ];

              // Espera a obtener todos los links.
              Promise.all(downloadTasks).then((urls) => {
                // Genera una nueva ImageUrl con todos los links de descarga para las imagenes de distinto tamaño.
                this.$emit(
                  "input",
                  new ImageUrl(name, urls[0], urls[1], urls[2])
                );
                this.cancel();
              });
            })
            // Finalmente, da por finalizado el proceso de carga.
            .finally(() => (this.uploading = false));
        };

        image.src = readerEvent.target.result as string;
      };

      reader.readAsDataURL(this.file);
    },
  },
});
