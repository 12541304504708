import { ActionContext } from 'vuex';
import { RootState } from '../states/RootState';
import { RestaurantState, ActiveTables } from '../states/RestaurantState';

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { ImageUrl, Restaurant, RestaurantFields, RestaurantFilters, RestaurantFiltersFields, RestaurantHours, RestaurantHoursFields } from 'models';

export default {
    state: <RestaurantState>{
        id: '',
        fetching: false,
        activeTables: {},
        currentRestaurant: null,
        hours: RestaurantHours.empty(),
        filters: RestaurantFilters.empty(),
        relatedImages: []
    },
    mutations: {
        SET_FETCHING_RESTAURANT(state: RestaurantState, value: boolean) {
            console.debug('Restaurant/mutations/SET_FETCHING_RESTAURANT\nvalue:', value);
            state.fetching = value;
        },
        SET_RESTAURANT_ID(state: RestaurantState, id: string) {
            console.debug('Restaurant/mutations/SET_RESTAURANT_ID\nid:', id);
            state.id = id;
        },
        SET_ACTIVE_TABLES(state: RestaurantState, tables: Array<any>) {
            console.debug('Restaurant/mutations/SET_ACTIVE_TABLES\ntables:', tables);
            tables.forEach((data: { tableName: string, tableId: string }) => state.activeTables[data.tableId] = data.tableName);
        },
        SET_RESTAURANT(state: RestaurantState, restaurant: Restaurant) {
            console.debug('Restaurant/mutations/SET_RESTAURANT\nrestaurant:', restaurant);
            state.currentRestaurant = restaurant;
        },
        SET_RESTAURANT_FILTERS(state: RestaurantState, filters: RestaurantFilters) {
            console.debug('Restaurant/mutations/SET_RESTAURANT_FILTERS\nfilters:', filters);
            state.filters = filters;
        },
        SET_RESTAURANT_HOURS(state: RestaurantState, hours: RestaurantHours) {
            console.debug('Restaurant/mutations/SET_RESTAURANT_HOURS\nhours:', hours);
            state.hours = hours;
        },
        SET_RESTAURANT_RELATED_IMAGES(state: RestaurantState, images: Array<ImageUrl>) {
            console.debug('Restaurant/mutations/SET_RESTAURANT_RELATED_IMAGES\nimages:', images);
            state.relatedImages = images;
        }
    },
    actions: {
        loadRestaurant(context: ActionContext<RestaurantState, RootState>) {
            console.debug("Restaurant/actions/loadRestaurant");

            context.commit('SET_FETCHING_RESTAURANT', true);

            context.commit("SET_RESTAURANT_ID", context.rootState.account.restaurantId);

            firebase.firestore().collection("restaurants").doc(context.state.id).get().then(doc => {
                const resData = doc.data();

                const restaurant = new Restaurant(context.state.id, <RestaurantFields>resData);

                context.commit('SET_RESTAURANT', restaurant);

                // if (resData !== undefined) {
                //     context.commit("SET_ACTIVE_TABLES", resData.tablesStructure || []);
                // }

                // Obtiene los filtros del restaurante.
                firebase.firestore().collection(`restaurants/${context.state.id}/details`).doc('filters').get().then((document) => {
                    context.commit(
                        'SET_RESTAURANT_FILTERS',
                        document.exists ? new RestaurantFilters('hours', <RestaurantFiltersFields>document.data()) : RestaurantFilters.empty()
                    );
                });

                // Obtiene los horarios de atención del restaurante.
                firebase.firestore().collection(`restaurants/${context.state.id}/details`).doc('hours').get().then((document) => {
                    context.commit(
                        'SET_RESTAURANT_HOURS',
                        document.exists ? new RestaurantHours('hours', <RestaurantHoursFields>document.data()) : RestaurantHours.empty()
                    );
                });

                // Obtiene otros datos asociados al restaurante.
                firebase.firestore().collection(`restaurants/${context.state.id}/details`).doc('related').get().then((document) => {
                    const images = document.data()?.images ?? [];
                    context.commit('SET_RESTAURANT_RELATED_IMAGES', images as Array<ImageUrl>);
                });

                context.dispatch('getMenus').then(() => {
                    // // Gets the reference to the tables collection of this restaurant.
                    // const tablesCollection = db.collection("restaurants").doc(context.state.id).collection("tables");
                    // // Subscribes to each active table of the restaurant.
                    // for (const [tableId, tableName] of Object.entries(context.state.activeTables)) {
                    //     console.debug('Subscribing to ', tableName, '[', tableId, ']');
                    //     tablesCollection.doc(tableId).onSnapshot(table => context.dispatch('onTableChange', { table }));
                    // }

                    context.commit('SET_FETCHING_RESTAURANT', false);

                    if (context.state.currentRestaurant?.fields.canBook) {
                        context.dispatch('initializeReservations');
                    }
                });
            });
        },
        updateRestaurantImageUrl(
            context: ActionContext<RestaurantState, RootState>,
            data: { imageUrlName: string, imageUrlData: ImageUrl },
        ) {
            console.debug("Restaurant/actions/updateRestaurantImageUrl\ndata:", data);

            const callable = firebase.functions().httpsCallable('updateRestaurantImageUrl');

            return callable(data).then((response) => {
                context.commit('SET_RESTAURANT', new Restaurant(context.state.id, <RestaurantFields>response.data));
            });
        },
        updateRestaurantRelatedImages(context: ActionContext<RestaurantState, RootState>, data: { relatedImages: Array<ImageUrl> }) {
            console.debug("Restaurant/actions/updateRestaurantRelatedImages\ndata:", data);

            const callable = firebase.functions().httpsCallable('updateRestaurantRelatedImages');

            return callable(data).then((response) => {
                context.commit('SET_RESTAURANT_RELATED_IMAGES', response.data as Array<ImageUrl>);
            });
        },
        updateRestaurantData(context: ActionContext<RestaurantState, RootState>, restaurant: Restaurant) {
            console.debug("Restaurant/actions/updateRestaurantData\nrestaurant:", restaurant);

            const callable = firebase.functions().httpsCallable('updateRestaurantData');

            const data = {
                // Información de resumen.
                description: restaurant.fields.description,
                cuisineType: restaurant.fields.cuisineType,
                tags: restaurant.fields.tags,
                // Redes sociales y contacto.
                phone: restaurant.fields.phone,
                wpPhone: restaurant.fields.wpPhone,
                instagramLink: restaurant.fields.instagramLink,
                facebookLink: restaurant.fields.facebookLink,
                // Datos del chef.
                chefName: restaurant.fields.chefName,
                chefDescription: restaurant.fields.chefDescription,
                chefTags: restaurant.fields.chefTags,
                // Colores.
                mainColor1: restaurant.fields.mainColor1,
                mainColor2: restaurant.fields.mainColor2,
                mainColor3: restaurant.fields.mainColor3,
                mainColor4: restaurant.fields.mainColor4,
                secondaryColor1: restaurant.fields.secondaryColor1,
                secondaryColor2: restaurant.fields.secondaryColor2,
                commentsColor: restaurant.fields.commentsColor,
                // Opciones avanzadas.
                isPublished: restaurant.fields.isPublished,
                canBook: restaurant.fields.canBook,
                isDeleted: restaurant.fields.isDeleted,
            };

            return callable(data).then((response) => {
                context.commit('SET_RESTAURANT', new Restaurant(context.state.id, <RestaurantFields>response.data));
            });
        },
        updateRestaurantHours(context: ActionContext<RestaurantState, RootState>, hours: RestaurantHours) {
            console.debug("Restaurant/actions/updateRestaurantHours\nhours:", hours);

            const callable = firebase.functions().httpsCallable('updateRestaurantHours');

            return callable(hours.fields).then((response) => {
                context.commit('SET_RESTAURANT_HOURS', hours);
            });
        },
        updateRestaurantFilters(context: ActionContext<RestaurantState, RootState>, filters: RestaurantFilters) {
            console.debug("Restaurant/actions/updateRestaurantFilters\nfilters:", filters);

            const callable = firebase.functions().httpsCallable('updateRestaurantFilters');

            return callable(filters.fields).then((response) => {
                context.commit('SET_RESTAURANT_FILTERS', filters);
            });
        },
    }
};