export const routes = [
    {
        path: '/',
        component: require('./components/Login').default
    },
    {
        path: '/login',
        component: require('./components/Login').default
    },
    {
        path: '/dashboard',
        component: require('./components/Dashboard').default,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: require('./components/Dashboard/Index').default
            },
            {
                path: 'admin',
                component: require('./components/Dashboard/Admin').default,
                children: [
                    {
                        path: '',
                        name: 'dashboard.admin.restaurants.index',
                        component: require('./components/Dashboard/Admin/RestaurantsIndex').default
                    },
                    {
                        path: 'restaurants/create',
                        name: 'dashboard.admin.restaurants.create',
                        component: require('./components/Dashboard/Admin/RestaurantsCreate').default
                    },
                    {
                        path: 'posts',
                        name: 'dashboard.admin.posts.index',
                        component: require('./components/Dashboard/Admin/PostsIndex').default
                    },
                    {
                        path: 'posts/create',
                        name: 'dashboard.admin.posts.create',
                        component: require('./components/Dashboard/Admin/PostsCreate').default
                    },
                ]
            },
            {
                path: 'editor',
                component: require('./components/Dashboard/Editor').default,
                children: [
                    {
                        path: '',
                        name: 'dashboard.editor.information',
                        component: require('./components/Dashboard/Editor/Information').default
                    },
                    {
                        path: 'filters',
                        name: 'dashboard.editor.filters',
                        component: require('./components/Dashboard/Editor/Filters').default
                    },
                    {
                        path: 'hours',
                        name: 'dashboard.editor.hours',
                        component: require('./components/Dashboard/Editor/Hours').default
                    },
                    {
                        path: 'images',
                        name: 'dashboard.editor.images',
                        component: require('./components/Dashboard/Editor/Images').default
                    },
                ]
            },
            {
                name: 'dashboard.comandas',
                path: 'comandas',
                component: require('./components/Dashboard/Comandas').default
            },
            {
                name: 'dashboard.tables',
                path: 'tables',
                component: require('./components/Dashboard/Tables').default
            },
            {
                name: 'dashboard.reservations',
                path: 'reservations',
                component: require('./components/Dashboard/Reservations').default
            },
            {
                name: 'dashboard.history',
                path: 'history',
                component: require('./components/Dashboard/History').default
            },
            {
                name: 'dashboard.menu',
                path: 'menu',
                component: require('./components/Dashboard/Menu').default
            },
        ]
    },

];