import Vue from 'vue';

export default {
    state: {
        orders: {},
        waiters: {},
    },
    mutations: {
        /**
         * Orders state.
         */
        ADD_TABLE_ORDER(state, { tableId, data }) {
            console.debug('Comandas/mutations/ADD_TABLE_ORDER\ntableId:', tableId, 'data:', data);
            if (!state.orders[tableId])
                Vue.set(state.orders, tableId, { ...data, loading: false, error: null });
        },
        SET_TABLE_ORDER_LOADING(state, { tableId, loading }) {
            console.debug('Comandas/mutations/SET_TABLE_ORDER_LOADING\nloading:', loading);
            state.orders[tableId].loading = loading;
        },
        SET_TABLE_ORDER_ERROR(state, { tableId, error }) {
            console.debug('Comandas/mutations/SET_TABLE_ORDER_ERROR\nerror:', error);
            state.orders[tableId].error = error;
        },
        REMOVE_TABLE_ORDER(state, { tableId }) {
            console.debug('Comandas/mutations/REMOVE_TABLE_ORDER\ntableId:', tableId);
            if (state.orders[tableId])
                Vue.delete(state.orders, tableId);
        },
        /**
         * Waiters state.
         */
        ADD_TABLE_WAITER(state, { tableId, data }) {
            console.debug('Comandas/mutations/ADD_TABLE_WAITER\ntableId:', tableId, 'data:', data);
            if (!state.waiters[tableId])
                Vue.set(state.waiters, tableId, { ...data, loading: false, error: null });
        },
        SET_TABLE_WAITER_LOADING(state, { tableId, loading }) {
            console.debug('Comandas/mutations/SET_TABLE_WAITER_LOADING\nloading:', loading);
            state.waiters[tableId].loading = loading;
        },
        SET_TABLE_WAITER_ERROR(state, { tableId, error }) {
            console.debug('Comandas/mutations/SET_TABLE_WAITER_ERROR\nerror:', error);
            state.waiters[tableId].error = error;
        },
        REMOVE_TABLE_WAITER(state, { tableId }) {
            console.debug('Comandas/mutations/REMOVE_TABLE_WAITER\ntableId:', tableId);
            if (state.waiters[tableId])
                Vue.delete(state.waiters, tableId);
        }
    },
    actions: {
        onTableChange({ rootState, commit }, { table }) {
            console.debug("Comandas/actions/onTableChange");

            const tablesCollection = window.firebase.firestore().collection("restaurants").doc(rootState.restaurant.id).collection("tables");
            
            const data = table.data();
            // New order waiting for response.
            if (data.waitingOrderResponse)
                tablesCollection.doc(table.id).collection('tableOrders').get().then((orders) => {
                    // Gets the products and quantities for this order.
                    let products = [];
                    orders.forEach(order => products.push({ idProduct: order.data().idProduct, quantity: order.data().quantity }));
                    // Adds the new order.
                    commit('ADD_TABLE_ORDER', { tableId: table.id, data: { timeStamp: new Date, products, comments: '' } });
                });
            // Order handled and has to be removed.
            else commit('REMOVE_TABLE_ORDER', { tableId: table.id });

            if (data.mozoStatus == 1) commit('ADD_TABLE_WAITER', { tableId: table.id, data: { timeStamp: new Date, status: 1 } });
            else if (data.mozoStatus == 2) commit('ADD_TABLE_WAITER', { tableId: table.id, data: { timeStamp: new Date, status: 2 } });
            else commit('REMOVE_TABLE_WAITER', { tableId: table.id });
        },
        confirmOrder({ rootState, commit }, { tableId, action }) {
            console.debug("Comandas/actions/confirmOrder\ntableId:", tableId, "action:", action);

            var confirmOrder = window.firebase.functions().httpsCallable('confirmOrder');
            const params = { menuId: rootState.restaurant.id, tableId, confirm: action };

            commit('SET_TABLE_ORDER_LOADING', { tableId, loading: true });

            confirmOrder(params)
                .then((response) => {
                    console.debug("Comandas/actions/confirmOrder\nfirebase response:", response);
                    commit('SET_TABLE_ORDER_ERROR', { tableId, error: null });
                })
                .catch((error) => {
                    console.debug("Comandas/actions/confirmOrder\nfirebase error:", error);
                    commit('SET_TABLE_ORDER_ERROR', { tableId, error });
                })
                .finally(() => commit('SET_TABLE_ORDER_LOADING', { tableId, loading: false }));
        },
        confirmWaiter({ rootState, commit }, { tableId, action }) {
            console.debug("Comandas/actions/confirmWaiter\ntableId:", tableId, "action:", action);

            var confirmWaiter = window.firebase.functions().httpsCallable('confirmWaiter');
            const params = { menuId: rootState.restaurant.id, tableId, confirm: action };

            commit('SET_TABLE_WAITER_LOADING', { tableId, loading: true });

            confirmWaiter(params)
                .then((response) => {
                    console.debug("Comandas/actions/confirmWaiter\nfirebase response:", response);
                    commit('SET_TABLE_WAITER_ERROR', { tableId, error: null });
                })
                .catch((error) => {
                    console.debug("Comandas/actions/confirmWaiter\nfirebase error:", error);
                    commit('SET_TABLE_WAITER_ERROR', { tableId, error });
                })
                .finally(() => commit('SET_TABLE_WAITER_LOADING', { tableId, loading: false }));
        }
    },
    getters: {
        requests(state, getters, rootState) {
            let requests = [];

            for (const [tableId, order] of Object.entries(state.orders))
                requests.push({
                    tableId,
                    loading: order.loading,
                    error: order.error,
                    type: 'comanda',
                    timeStamp: order.timeStamp,
                    title: rootState.restaurant.activeTables[tableId],
                    subtitle: 'NUEVA COMANDA',
                    lines: order.products.map(product => `${product.quantity}x ${rootState.menu.products.find(({ id }) => id == product.idProduct).productName}`),
                    comments: []
                });

            for (const [tableId, waiter] of Object.entries(state.waiters))
                requests.push({
                    tableId,
                    loading: waiter.loading,
                    error: waiter.error,
                    type: 'mozo',
                    timeStamp: waiter.timeStamp,
                    title: rootState.restaurant.activeTables[tableId],
                    subtitle: 'MOZO',
                    lines: [waiter.status == 1 ? 'Llama el mozo a la mesa' : 'Pide la cuenta'],
                    comments: []
                });

            return requests.sort((a, b) => a.timeStamp - b.timeStamp);
        }
    }
}