





































































































import { RestaurantFilters, ValidationErrors } from "models";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      loading: false,
      filters: RestaurantFilters.empty(),
      errors: new ValidationErrors(),
    };
  },
  beforeMount() {
    this.filters = this.$store.state.restaurant.filters.clone();
  },
  computed: {
    influences: {
      get(): string {
        return this.filters.fields.influences.join(",");
      },
      set(influences: string): void {
        this.filters.fields.influences = influences.split(",");
      },
    },
    neighborhoods: {
      get(): string {
        return this.filters.fields.neighborhoods.join(",");
      },
      set(neighborhoods: string): void {
        this.filters.fields.neighborhoods = neighborhoods.split(",");
      },
    },
  },
  methods: {
    save(): void {
      this.errors = this.filters.getValidationErrors();

      if (this.errors.isEmpty()) {
        this.loading = true;
        this.$store
          .dispatch("updateRestaurantFilters", this.filters)
          .finally(() => (this.loading = false));
      }
    },
  },
});
