<template>
  <input
    v-model="internalValue"
    @blur="onInput"
    @keyup="handleKeyUp"
    @keypress="isNumber($event)"
    @keypress.enter="onInput"
    :step="step"
    :min="min"
    :max="max"
    type="number"
    :style="`text-align: ${align}`"
  />
</template>

<script>
export default {
  props: {
    value: { type: [String, Number], required: true },
    step: { type: Number, required: false, default: 1 },
    min: { type: Number, required: false, default: null },
    max: { type: Number, required: false, default: null },
    align: { type: String, required: false, default: 'right' }
  },
  data() {
    return {
      internalValue: this.value,
      timeoutId: null,
      timeoutTime: 1000,
    };
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleKeyUp(e) {
      if (e.keyCode != 13) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(this.onInput, this.timeoutTime);
      }
    },
    onInput() {
      if (this.value != this.internalValue) {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
          this.timeoutId = null;
        }
        this.$emit("input", this.internalValue);
      }
    },
  },
};
</script>