






























































import Vue from "vue";
export default Vue.extend({
  props: {
    value: { type: Boolean, required: false, default: false },
    onText: { type: String, required: false, default: "SI" },
    offText: { type: String, required: false, default: "NO" },
  },
  data() {
    return { isOn: this.value };
  },
  methods: {
    onClick(value: boolean) {
      this.isOn = value;
      this.$emit("input", value);
    },
  },
});
