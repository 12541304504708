<style scoped>
div.wrapper {
	padding: 10px 30px;
}

div.comandas {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 37px;
	grid-column-gap: 20px;
}

.historico img {
	top: 7px;
	position: relative;
	margin-right: 10px;
}

div.title {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

div.title > h1 {
	display: inline;
}

div.title > button {
	display: block;
	margin-left: auto;
}

hr {
	background-color: #e0e0e0;
	height: 1px;
	border: 0;
}
</style>

<template>
	<div class="wrapper">
		<div class="title">
			<h1>Comandas</h1>

			<button class="btn historico">
				<img src="../../assets/img/historico_btn.svg" alt="historico" />Ver Historico de Comandas
			</button>
		</div>

		<div class="comandas">
			<div v-for="request in requests" :key="request.tableId + request.type">
				<color-card
					:loading="request.loading"
					:error="request.error"
					:time-stamp="request.timeStamp"
					:type="request.type"
					:title="request.title"
					:subtitle="request.subtitle"
					@confirm="type => handleConfirm(type, request.tableId)"
					@cancel="type => handleCancel(type, request.tableId)"
				>
					<div v-for="line in request.lines" :key="line">
						<span>{{ line }}</span>
					</div>

					<template v-if="request.comments.length > 0">
						<hr />
						<h4 v-for="comment in request.comments" :key="comment">{{ comment }}</h4>
					</template>
				</color-card>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		restaurantId() {
			return this.$store.state.comandas.restaurantId;
		},
		requests() {
			return this.$store.getters["requests"];
		},
	},
	methods: {
		handleConfirm(type, tableId) {
			this.$store.dispatch(
				type == "comanda" ? "confirmOrder" : "confirmWaiter",
				{ tableId, action: true }
			);
		},
		handleCancel(type, tableId) {
			this.$store.dispatch(
				type == "comanda" ? "confirmOrder" : "confirmWaiter",
				{ tableId, action: false }
			);
		},
	},
};
</script>