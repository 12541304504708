


























































import Vue from "vue";
export default Vue.extend({
  props: { visible: { type: Boolean, required: true } },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
  },
});
