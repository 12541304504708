





































































































































































import { ImageUrl } from "models";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      restaurant: this.$store.state.restaurant.currentRestaurant.clone(),
      updatingRelatedImages: false,
    };
  },
  computed: {
    relatedImages(): Array<ImageUrl> {
      return this.$store.state.restaurant.relatedImages;
    },
  },
  watch: {
    "restaurant.fields.headerImageUrl"(value) {
      this.$store.dispatch("updateRestaurantImageUrl", {
        imageUrlName: "headerImageUrl",
        imageUrlData: value,
      });
    },
    "restaurant.fields.launcherImageUrl"(value) {
      this.$store.dispatch("updateRestaurantImageUrl", {
        imageUrlName: "launcherImageUrl",
        imageUrlData: value,
      });
    },
    "restaurant.fields.loginImageUrl"(value) {
      this.$store.dispatch("updateRestaurantImageUrl", {
        imageUrlName: "loginImageUrl",
        imageUrlData: value,
      });
    },
    "restaurant.fields.logoImageUrl"(value) {
      this.$store.dispatch("updateRestaurantImageUrl", {
        imageUrlName: "logoImageUrl",
        imageUrlData: value,
      });
    },
    "restaurant.fields.chefImageUrl"(value) {
      this.$store.dispatch("updateRestaurantImageUrl", {
        imageUrlName: "chefImageUrl",
        imageUrlData: value,
      });
    },
  },
  methods: {
    getImageAsBackground(image: ImageUrl | null): string {
      return `background-image: url('${image != null ? image.image1 : ""}')`;
    },
    onRelatedImageUpload(image: ImageUrl) {
      this.updatingRelatedImages = true;
      this.$store
        .dispatch("updateRestaurantRelatedImages", {
          relatedImages: [image, ...this.relatedImages],
        })
        .finally(() => (this.updatingRelatedImages = false));
    },
    onRelatedImageDelete(index: number) {
      if (confirm("¿Seguro que desea eliminar la imagen?")) {
        this.updatingRelatedImages = true;
        this.$store
          .dispatch("updateRestaurantRelatedImages", {
            relatedImages: this.relatedImages.filter((_, idx) => idx !== index),
          })
          .finally(() => (this.updatingRelatedImages = false));
      }
    },
  },
});
