<style scoped>
div.modal {
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

div.modal > div.content {
  background-color: white;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: auto;
}

button.close-button {
  display: block;
  background-color: transparent;
  border: 0;
  outline: none;
  font-weight: bold;
  cursor: pointer;
}

div.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}
</style>

<template>
  <div>
    <transition name="slide">
      <div class="modal" v-if="visible">
        <div v-if="visible" class="content">
          <div class="title">
            <slot name="title" />
            <button class="close-button" @click="handleVisibility(false)">
              <img src="../assets/img/close.svg" alt="Cerrar" />
            </button>
          </div>

          <div>
            <slot />
          </div>
        </div>
      </div>
    </transition>

    <div @click="handleVisibility(true)" style="display: inline">
      <slot name="button" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: { type: Boolean, required: true },
  },
  methods: {
    handleVisibility(visible) {
      this.$emit("update:visible", visible);
    },
  },
};
</script>