




















































































































































import Vue from "vue";
import { Reservation } from "models";
import ReservationConfigModal from "./Reservations/Config.vue";
import ReservationCreationModal from "./Reservations/Create.vue";

export default Vue.extend({
  components: { ReservationConfigModal, ReservationCreationModal },
  computed: {
    calendarHighlights(): Array<Object> {
      return [
        {
          key: "today",
          highlight: { color: "green", fillMode: "outline" },
          dates: new Date(),
        },
      ];
    },
    tomorrow(): Date {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      return date;
    },
    todayReservations(): Array<Reservation> {
      return this.$store.state.reservations.todayReservations;
    },
    otherReservations(): Array<Reservation> {
      return this.$store.state.reservations.otherReservations;
    },
    otherReservationsDate: {
      get(): Date {
        return this.$store.state.reservations.otherReservationsDate;
      },
      set(date: Date) {
        this.$store.dispatch("listenOtherReservations", { date });
      },
    },
    otherReservationsLoading(): boolean {
      return this.$store.state.reservations.otherReservationsLoading;
    },
    // Si la fecha que se muestra en el listado de otras reservas es el día de mañana, no se puede seguir yendo para atras.
    disableOtherReservationsDatePrevButton(): boolean {
      const today = new Date();
      return (
        today.getFullYear() == this.otherReservationsDate.getFullYear() &&
        today.getMonth() == this.otherReservationsDate.getMonth() &&
        today.getDate() + 1 == this.otherReservationsDate.getDate()
      );
    },
  },
  filters: {
    prettyDate(date: Date): string {
      const weekDay = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"][date.getDay()];
      const month = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"][date.getMonth()];
      const year = date.getFullYear();
      const day = date.getDate();
      const today = new Date();
      const sameMonth = date.getMonth() == today.getMonth();
      const sameYear = date.getFullYear() == today.getFullYear();

      return `${weekDay} ${day} ${!sameMonth || !sameYear ? "," : ""} ${sameMonth ? "" : month} ${sameYear ? "" : year}`;
    },
  },
  methods: {
    nextOtherReservationsDate(): void {
      let date = new Date(this.otherReservationsDate);

      date.setDate(this.otherReservationsDate.getDate() + 1);

      this.otherReservationsDate = date;
    },
    prevOtherReservationsDate(): void {
      let date = new Date(this.otherReservationsDate);

      date.setDate(this.otherReservationsDate.getDate() - 1);

      this.otherReservationsDate = date;
    },
  },
});
