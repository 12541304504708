



























































































































import Vue from "vue";
import firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import "firebase/auth";

export default Vue.extend({
  mounted() {
    const firebaseUI =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());

    firebaseUI.start("#firebaseui-auth-container", {
      signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
      signInSuccessUrl: "/#/dashboard/menu",
      credentialHelper: "none",
    });
  },
});
