








import { ValidationErrors } from "models";
import Vue from "vue";
export default Vue.extend({
  props: {
    errors: { type: ValidationErrors, required: true },
    field: { type: String, required: true },
  },
});
