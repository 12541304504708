





















































import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      restaurantSearch: "",
    };
  },
  computed: {
    isAdminUser(): boolean {
      return this.$store.state.account.isAdminUser;
    },
    currentRestaurant() {
      return this.$store.state.restaurant.id;
    },
    restaurants() {
      return this.$store.state.account.restaurants.filter(
        (res: { id: string; name: string }) =>
          res.id.toUpperCase().includes(this.restaurantSearch.toUpperCase()) ||
          res.name.toUpperCase().includes(this.restaurantSearch.toUpperCase())
      );
    },
  },
  created() {
    if (!this.isAdminUser) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    create() {
      this.$router.push({ name: "dashboard.admin.restaurants.create" });
    },
    selectRestaurant(restaurantId: string) {
      if (restaurantId !== this.currentRestaurant) {
        this.$store
          .dispatch("setAdminRestaurantId", { restaurantId })
          .then(() => this.$router.push("/dashboard"));
      } else {
        this.$router.push("/dashboard");
      }
    },
  },
});
