


















































































































import Vue from "vue";
import { Reservation, CancellationType } from "models";

export default Vue.extend({
  props: {
    reservation: { type: Reservation, required: true },
    basic: { type: Boolean, required: false, default: false },
  },
  computed: {
    loading(): boolean {
      return this.$store.state.reservations.loadingReservationsId.includes(this.reservation.fields.restaurantReservationId);
    },
    type(): string {
      if (this.reservation.fields.cancelledAt) {
        return this.reservation.fields.cancellationType == CancellationType.BY_RESTAURANT_FROM_ADMINWEBAPP
          ? "reserva_cancelada"
          : "reserva_cancelada_cliente";
      } else if (this.basic) {
        return "reserva";
      } else if (this.reservation.fields.arrived === true) {
        return "reserva_activa";
      } else if (this.reservation.fields.arrived === false) {
        return "reserva_perdida";
      } else {
        return "reserva_pendiente";
      }
    },
    subtitle(): string {
      switch (this.type) {
        case "reserva_pendiente":
          return "RESERVA PENDIENTE";

        case "reserva_activa":
          return "RESERVA ACTIVA";

        case "reserva_perdida":
          return "RESERVA PERDIDA - CLIENTE NO VINO";

        case "reserva_cancelada":
          return "RESERVA CANCELADA POR NOSOTROS";

        case "reserva_cancelada_cliente":
          return "RESERVA CANCELADA POR CLIENTE";

        default:
          return "RESERVA";
      }
    },
    hour(): string {
      return (
        this.reservation.fields.date.getHours().toString().padStart(2, "0") +
        ":" +
        this.reservation.fields.date.getMinutes().toString().padStart(2, "0")
      );
    },
    peopleWord(): string {
      return this.reservation.fields.people > 1 ? "Comensales" : "Comensal";
    },
    hasButtons(): boolean {
      return this.type == "reserva_pendiente";
    },
  },
  methods: {
    handleConfirm() {
      if (confirm("¿Desea confirmar que el cliente llegó?")) {
        this.$store.dispatch("updateReservationArrival", {
          restaurantReservationId: this.reservation.fields.restaurantReservationId,
          arrived: true,
        });
      }
    },
    handleCancel() {
      if (confirm("¿Desea confirmar que el cliente no llegó?")) {
        this.$store.dispatch("updateReservationArrival", {
          restaurantReservationId: this.reservation.fields.restaurantReservationId,
          arrived: false,
        });
      }
    },
    handleCancellation(requestedByClient: boolean) {
      if (confirm("¿Seguro que desea marcar la reserva como cancelada?")) {
        this.$store.dispatch("cancelReservation", {
          restaurantReservationId: this.reservation.fields.restaurantReservationId,
          requestedByClient,
        });
      }
    },
  },
});
