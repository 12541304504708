



















































































































































































































import { Post, ValidationErrors } from "models";
import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      imageBase64: "",
      post: Post.empty(),
      errors: new ValidationErrors(),
    };
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.$store
        .dispatch("getPostById", { postId: this.$route.query.id })
        .then((post: Post) => (this.post = post));
    }
  },
  computed: {
    loading() {
      return this.$store.state.posts.savingPost;
    },
    tagsAsArray(): Array<string> {
      return this.post.fields.tags.split(",").filter((tag) => tag);
    },
    dateToString(): string {
      return moment(this.post.fields.date).format("DD-MM-YY");
    },
    restaurants() {
      return this.$store.state.account.restaurants;
    },
    headerBackgroundImage(): string {
      return this.imageBase64 || this.post.fields.mainImageUrl.image1;
    },
  },
  methods: {
    onFileChange(e: any) {
      const file = e.target.files[0];

      var FR = new FileReader();

      FR.addEventListener(
        "load",
        (e: any) => (this.imageBase64 = e.target.result)
      );

      FR.readAsDataURL(file);
    },
    getRestaurantById(restaurantId: string) {
      return this.restaurants.find(
        (restaurant: any) => restaurant.id == restaurantId
      );
    },
    onRestaurantClick(restaurantId: string) {
      const index = this.post.fields.relatedRestaurants.indexOf(restaurantId);

      if (index < 0) {
        this.post.fields.relatedRestaurants.push(restaurantId);
      } else {
        this.post.fields.relatedRestaurants.splice(index, 1);
      }
    },
    onSave() {
      this.errors = this.post.getValidationErrors();

      if (this.errors.isEmpty()) {
        this.$store
          .dispatch("savePost", {
            imageBase64: this.imageBase64,
            post: this.post,
          })
          .then(() => this.$router.back());
      }
    },
  },
});
