import { ActionContext } from "vuex";
import { AccountState } from "../states/AccountState";
import { RootState } from "../states/RootState";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

export default {
    state: <AccountState>{
        loading: false,
        id: '',
        restaurantId: '',
        isAdminUser: false,
        restaurants: [],
    },
    mutations: {
        SET_ACCOUNT_LOADING(state: AccountState, data: { loading: boolean }) {
            console.debug('Account/mutations/SET_ACCOUNT_LOADING\n', data)
            state.loading = data.loading;
        },
        SET_ACCOUNT_ID(state: AccountState, data: { id: string }) {
            console.debug('Account/mutations/SET_ACCOUNT_ID\n', data)
            state.id = data.id;
        },
        SET_ACCOUNT_RESTAURANT_ID(state: AccountState, data: { restaurantId: string }) {
            console.debug('Account/mutations/SET_ACCOUNT_RESTAURANT_ID\n', data)
            state.restaurantId = data.restaurantId;
        },
        SET_IS_ADMIN_USER(state: AccountState, data: { isAdminUser: boolean }) {
            console.debug('Account/mutations/SET_IS_ADMIN_USER\n', data)
            state.isAdminUser = data.isAdminUser;
        },
        SET_RESTAURANTS(state: AccountState, data: { restaurants: Array<Object> }) {
            console.debug('Account/mutations/SET_RESTAURANTS\n', data)
            state.restaurants = data.restaurants;
        }
    },
    actions: {
        initializeAccountData(context: ActionContext<AccountState, RootState>, data: { id: string, token: firebase.auth.IdTokenResult }) {
            console.debug('Account/actions/initializeAccountData');

            return new Promise<boolean>((resolve) => {
                firebase.firestore().collection("restaurantUsers").doc(data.id).get().then(doc => {
                    // Obtiene el id del restaurante asociado a esta cuenta.
                    context.commit('SET_ACCOUNT_RESTAURANT_ID', { restaurantId: doc.data()?.restaurantId });

                    context.commit('SET_ACCOUNT_LOADING', { loading: true });

                    context.commit('SET_ACCOUNT_ID', { id: data.id });

                    // Si el usuario es admin, obtiene el listado de restaurantes para trabajar con el.
                    if (data.token.claims.admin === true) {
                        context.commit('SET_IS_ADMIN_USER', { isAdminUser: true });

                        firebase.functions().httpsCallable('getRestaurantsForAdmin')().then((response) => {
                            context.commit('SET_RESTAURANTS', { restaurants: response.data });
                            context.commit('SET_ACCOUNT_LOADING', { loading: false });

                            resolve(context.state.isAdminUser);
                        });
                    }
                    // En caso contrario, la inicialización se ya se encuentra terminada.
                    else {
                        context.commit('SET_ACCOUNT_LOADING', { loading: false });
                        resolve(context.state.isAdminUser);
                    }
                });
            })
        },
        setAdminRestaurantId(context: ActionContext<AccountState, RootState>, data: { restaurantId: string }) {
            console.debug('Account/actions/setAdminRestaurantId\n', data);

            context.commit('SET_ACCOUNT_LOADING', { loading: true });

            const callable = firebase.functions().httpsCallable('updateUserRestaurantForAdmin');

            return new Promise<void>((resolve) => {
                callable(data).then(() => resolve()).finally(() => {
                    context.commit('SET_ACCOUNT_RESTAURANT_ID', { restaurantId: data.restaurantId });
                    context.commit('SET_ACCOUNT_LOADING', { loading: false });
                });
            });
        }
    },
    getters: {
        accountIsReady(state: AccountState): boolean {
            return state.id !== '';
        }
    }
};
