






































































import Vue from "vue";
export default Vue.extend({
  props: {
    restaurant: { type: Object, required: true },
    selected: { type: Boolean, required: false, default: false },
  },
  methods: {
    onClick() {
      this.$emit("click", this.restaurant.id);
    },
  },
});
