
























































import { RestaurantHours, ValidationErrors } from "models";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      loading: false,
      hours: RestaurantHours.empty(),
      errors: new ValidationErrors(),
    };
  },
  beforeMount() {
    this.hours = this.$store.state.restaurant.hours.clone();
  },
  computed: {
    lunes: {
      get(): string {
        return this.hours.fields.lunes.join(",");
      },
      set(hours: string): void {
        this.hours.fields.lunes = hours.split(",");
      },
    },
    martes: {
      get(): string {
        return this.hours.fields.martes.join(",");
      },
      set(hours: string): void {
        this.hours.fields.martes = hours.split(",");
      },
    },
    miercoles: {
      get(): string {
        return this.hours.fields.miercoles.join(",");
      },
      set(hours: string): void {
        this.hours.fields.miercoles = hours.split(",");
      },
    },
    jueves: {
      get(): string {
        return this.hours.fields.jueves.join(",");
      },
      set(hours: string): void {
        this.hours.fields.jueves = hours.split(",");
      },
    },
    viernes: {
      get(): string {
        return this.hours.fields.viernes.join(",");
      },
      set(hours: string): void {
        this.hours.fields.viernes = hours.split(",");
      },
    },
    sabado: {
      get(): string {
        return this.hours.fields.sabado.join(",");
      },
      set(hours: string): void {
        this.hours.fields.sabado = hours.split(",");
      },
    },
    domingo: {
      get(): string {
        return this.hours.fields.domingo.join(",");
      },
      set(hours: string): void {
        this.hours.fields.domingo = hours.split(",");
      },
    },
  },
  methods: {
    save(): void {
      this.errors = this.hours.getValidationErrors();

      if (this.errors.isEmpty()) {
        this.loading = true;
        this.$store
          .dispatch("updateRestaurantHours", this.hours)
          .finally(() => (this.loading = false));
      }
    },
  },
});
