























































import Vue from "vue";
export default Vue.extend({
  props: {
    loading: { type: Boolean, required: false, default: false },
    backgroundColor: { type: String, required: false, default: "#f7f7f7" },
    saveText: { type: String, required: false, default: "Guardar" },
    cancelText: { type: String, required: false, default: "Cancelar" },
    saveVisible: { type: Boolean, required: false, default: true },
  },
  methods: {
    onSave() {
      this.$emit("save");
    },
    onCancel() {
      this.$emit("cancel");
    },
  },
});
