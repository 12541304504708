





























































































































































































































import Vue from "vue";
import { ValidationErrors } from "models";

export default Vue.extend({
  data() {
    return {
      loading: false,
      restaurant: this.$store.state.restaurant.currentRestaurant.clone(),
      errors: new ValidationErrors(),
    };
  },
  computed: {
    tags: {
      get(): string {
        return this.restaurant.fields.tags?.join(",") ?? "";
      },
      set(tags: string): void {
        this.restaurant.fields.tags = tags.split(",");
      },
    },
    chefTags: {
      get(): string {
        return this.restaurant.fields.chefTags?.join(",") ?? "";
      },
      set(tags: string): void {
        this.restaurant.fields.chefTags = tags.split(",");
      },
    },
  },
  methods: {
    cancel(): void {
      if (confirm("¿Seguro que desea descartar los cambios?")) {
        this.restaurant = this.$store.state.restaurant.currentRestaurant.clone();
      }
    },
    save(): void {
      this.errors = this.restaurant.getValidationErrors();

      if (this.errors.isEmpty()) {
        this.loading = true;
        this.$store
          .dispatch("updateRestaurantData", this.restaurant)
          .finally(() => (this.loading = false));
      }
    },
  },
});
