






























































































































































































































import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";

export default Vue.extend({
  computed: {
    logoUrl() {
      return this.$store.state.restaurant.currentRestaurant.fields
        .launcherImageUrl.image1;
    },
    comandasCount() {
      return this.$store.getters["requests"].length;
    },
    isAdminUser() {
      return this.$store.state.account.isAdminUser;
    },
    hasReservationsEnabled() {
      return this.$store.state.restaurant.currentRestaurant.fields.canBook;
    },
  },
  methods: {
    signOut() {
      if (confirm("¿Seguro que desea cerrar sesión?")) {
        firebase
          .auth()
          .signOut()
          .then(() => location.reload());
      }
    },
  },
});
