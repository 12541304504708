import Vuex from "vuex";

export default new Vuex.Store({
    state: {
        userTokenReady: false,
    },
    modules: {
        account: require('./modules/Account.ts').default,
        restaurant: require('./modules/Restaurant.ts').default,
        comandas: require('./modules/Comandas').default,
        tables: require('./modules/Tables').default,
        menu: require('./modules/Menu').default,
        reservations: require('./modules/Reservations.ts').default,
        posts: require('./modules/Posts.ts').default
    },
});