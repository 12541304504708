<style scoped>
div.wrapper {
	display: flex;
	position: relative;
	background-color: #f0f0f0;
}

/**
 * Tables list
 */
/* List styles */
div.tables-list {
	display: inline-block;
	width: 35%;
	background-color: #f7f7f7;
}

div.tables-list > h1 {
	padding: 10px 30px;
	margin-bottom: 0px;
}

/* Items style */
div.tables-list > div.table {
	padding: 20px 30px;
	margin: 0px;
	display: flex;
	align-items: center;
	position: relative;
}

div.tables-list > div.table::before {
	content: "";
	position: absolute;
	left: 30px;
	bottom: 0;
	height: 1px;
	width: calc(100% - 60px);
	border-bottom: solid 1px #d0d0d0;
}

div.tables-list > div.table:hover {
	cursor: pointer;
	background-color: rgb(245, 245, 245);
}

div.tables-list > div.table.selected {
	background-color: rgb(243, 243, 243);
	box-sizing: border-box;
	border-left: 2px solid #f7c43b;
	cursor: initial;
}

div.tables-list > div.table::after {
	content: url("../../assets/img/arrow.svg");
	margin-left: 15px;
}

/* Item content style */
div.tables-list > div.table > h2.name {
	display: inline-block;
}

div.tables-list > div.table > div.name.disabled {
	color: #adadad;
}

div.tables-list > div.table > div.table_tags {
	margin-left: 30px;
	display: inline-block;
}

div.tables-list > div.table > div.table_tags > div.tag {
	font-size: 10px;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	letter-spacing: 2px;
	padding: 4px 15px;
	display: inline-block;
	border-radius: 12px;
}

div.tables-list > div.table > div.table_tags > div.tag.tag_ocupada {
	background-color: #fef3d6;
	color: #f7c43b;
}

div.tables-list > div.table > div.table_tags > div.tag.tag_reserva {
	background-color: #e1f4e9;
	color: #6be29d;
}

div.tables-list > div.table > div.notifications {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-color: #ff6227;
	padding: 6px;
	color: #ffffff;
	font-size: 15px;
	margin-left: auto;
}

/**
 * Table session
 */
div.table-session {
	display: inline-block;
	width: 65%;
	min-height: 100vh;
	padding: 10px 50px;
	box-sizing: border-box;
	position: relative;
}

div.table-session.loading::after {
	content: "Cargando ...";
	background-color: rgba(255, 255, 255, 0.8);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	padding-top: 50px;
	justify-content: center;
	font-size: 200%;
	font-weight: bold;
}

div.table-session > div.header {
	display: flex;
	align-items: center;
	padding: 15px 0;
}

div.table-session > div.placeholder-message {
	text-align: center;
}

div.table-session > div.header > h1 {
	display: inline-block;
	margin: 0;
	margin-right: auto;
	margin-bottom: 0;
}

div.table-session > div.header > button + button {
	margin-left: 5px;
}

div.table-session > div.header > button.reserva_btn {
	background-color: #6be29d;
}

div.table-session > div.header > button.gris_btn {
	background-color: #adadad;
}

div.table-session > div.header > button.gris_btn:hover {
	background-color: #c1c1c1;
}

div.table-session > div.header > button.more_btn {
	position: relative;
	top: 5px;
}
</style>

<template>
	<div class="wrapper">
		<div class="tables-list">
			<h1>Mesas</h1>
			<div
				v-for="table in tables"
				v-bind:key="table.id"
				class="table"
				:class="{ 'selected': table.id == selectedTableId }"
				@click="open(table)"
			>
				<h2 class="name" :class="{ 'disabled': !table.enabled }">{{ table.name }}</h2>

				<div class="table_tags">
					<div :class="table.status | statusTags">{{ table.status | statusName }}</div>
				</div>

				<div v-if="getNotifications(table) > 0" class="notifications">{{ getNotifications(table) }}</div>
			</div>
		</div>

		<div class="table-session" :class="{'loading': loading }">
			<template v-if="currentTableSession != null">
				<div class="header">
					<h1>{{ currentTableSession.tableName }}</h1>

					<button class="btn btn-filled gris_btn">CERRAR MESA</button>

					<button class="btn btn-filled reserva_btn">HABILITAR PARA RESERVA</button>

					<button class="btn">
						<img src="../../assets/img/menu.svg" alt="más" />
					</button>
				</div>

				<historical-card
					v-for="event in currentTableSession.events"
					:key="event.id"
					:time-stamp="event.timeStamp"
					:type="event.type"
				>
					<template v-if="event.type == 'ORDER_PLACED'">
						<div v-for="item in event.data" :key="item.productId">
							<span>{{ item.quantity }}x {{ item.productName }}</span>
						</div>
					</template>
				</historical-card>

                <small><i>Mesa abierta</i></small>
			</template>
			<div class="placeholder-message" v-else>
				<h1 v-if="selectedTableId == null">Seleccione una mesa del listado</h1>
				<h1 v-else>La mesa seleccionada no posee un historial abierto</h1>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			selectedTableId: null,
		};
	},
	computed: {
		tables() {
			return this.$store.getters["tablesList"];
		},
		currentTableSession() {
			const table = this.$store.getters["tablesList"].find(
				({ id }) => id == this.selectedTableId
			);
			return table ? table.session : null;
		},
		loading() {
			return this.$store.state.tables.loading;
		},
	},
	filters: {
		statusName(status) {
			switch (status) {
				case 0:
				case 20:
					return "";
				case 10:
					return "OCUPADA";
				default:
					return "Sin definir";
			}
		},
		statusTags(status) {
			//otros: tag_deshabilitada tag_reserva
			switch (status) {
				case 0:
				case 20:
					return "tag";

				case 10:
					return "tag tag_ocupada";

				default:
					return "";
			}
		},
	},
	methods: {
		open(table) {
			this.selectedTableId = table.id;
		},
		getNotifications(table) {
			return this.$store.getters["requests"].filter(
				({ tableId }) => tableId == table.id
			).length;
		},
	},
};
</script>