








































































import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";

export default Vue.extend({
  computed: {
    currentPath() {
      return this.$route.path.replace("/admin", "").split("/").join(" / ");
    },
  },
  methods: {
    goToEditor() {
      this.$router.push("/dashboard");
    },
    goBack() {
      if (this.$router.currentRoute.name == "admin")
        this.$router.push("/dashboard");
      else this.$router.back();
    },
    signOut() {
      if (confirm("¿Seguro que desea cerrar sesión?")) {
        firebase
          .auth()
          .signOut()
          .then(() => location.reload());
      }
    },
  },
});
