


































import Vue from "vue";
export default Vue.extend({
  computed: {
    restaurantName() {
      return this.$store.state.restaurant.currentRestaurant.fields.name;
    },
  },
});
