
































































































































































































































































































































import Vue from "vue";
import { ValidationErrors, ReservationConfig, DayOfWeek } from "models";

export default Vue.extend({
  data() {
    return {
      visible: false,
      config: ReservationConfig.empty(),
      validationErrors: new ValidationErrors(),
      daysStructure: {
        lun: "Lunes",
        mar: "Martes",
        mie: "Miércoles",
        jue: "Jueves",
        vie: "Viernes",
        sab: "Sábado",
        dom: "Domingo",
      },
    };
  },
  computed: {
    loading(): boolean {
      return this.$store.state.reservations.loadingReservationsConfig;
    },
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      this.config = this.$store.state.reservations.config.clone();
      this.validationErrors = new ValidationErrors();
    },
    setCancellationRange(rangeInHours: number) {
      this.config.cancellationHoursRange = rangeInHours;
    },
    addDayHour(day: DayOfWeek) {
      this.config.addHour(day, "00:00");
    },
    updateDayHour(day: DayOfWeek, index: number, value: string) {
      this.config.updateHour(day, index, value);
    },
    removeDayHour(day: DayOfWeek, index: number) {
      this.config.removeHour(day, index);
    },
    addNotWorkingDate() {
      this.config.addNotWorkingDate("1/1");
    },
    updateNotWorkingDate(index: number, newDay: string, newMonth: string) {
      let day = isNaN(parseInt(newDay)) ? this.config.fields.notWorkingDates[index].split("/")[0] : newDay;
      let month = isNaN(parseInt(newMonth)) ? this.config.fields.notWorkingDates[index].split("/")[1] : newMonth;

      this.config.fields.notWorkingDates[index] = `${day}/${month}`;
    },
    removeNotWorkingDate(index: number) {
      this.config.fields.notWorkingDates.splice(index, 1);
    },
    addPlace() {
      const index = this.config.fields.places.length + 1;
      this.config.addTablesForTwo(1, `Zona ${index}`);
    },
    removePlace(name: string) {
      this.config.removePlace(name);
    },
    //
    save() {
      this.validationErrors = this.config.getValidationErrors();

      if (this.validationErrors.isEmpty()) {
        this.config.fields.updatedAt = new Date();
        this.$store.dispatch("updateReservationsConfig", { config: this.config });
      }
    },
  },
});
