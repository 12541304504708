

























































































































































import Vue, { PropType } from "vue";
import { DayOfWeek, Helpers, ReservationConfig } from "models";
import moment from "moment";

export default Vue.extend({
  props: {
    date: { type: Date as PropType<Date>, required: true, default: () => new Date() },
  },
  data() {
    return {
      visible: false,
      fields: {
        date: this.date,
        hour: "",
        people: 0,
        place: "",
        name: "",
        phone: "",
        notes: "",
      },
      error: "",
    };
  },
  computed: {
    loading(): boolean {
      return this.$store.state.reservations.creatingReservation;
    },
    config(): ReservationConfig {
      return this.$store.state.reservations.config;
    },
    dayOfWeek(): DayOfWeek {
      return Helpers.dayOfWeekFromDate(this.fields.date);
    },
    peopleRange(): Array<number> {
      let numbers = [];

      for (let i = this.$store.state.reservations.config.fields.minPeople; i <= this.$store.state.reservations.config.fields.maxPeople; i++) {
        numbers.push(i);
      }

      return numbers;
    },
  },
  methods: {
    initForm(): void {
      this.error = "";

      this.fields = {
        date: new Date(`${this.date.getFullYear()}-${this.date.getMonth() + 1}-${this.date.getDate()} 00:00:00`),
        hour: "",
        people: 0,
        place: "",
        name: "",
        phone: "",
        notes: "",
      };
    },
    save(): void {
      this.error = "";

      // // TODO: eliminar la validación del campo place cuando el restaurante no posee zonas específicas.
      //   if (Object.entries(this.fields).filter(([k, v]) => k === "notes" || !!v).length != Object.keys(this.fields).length) {
      //     this.error = "Todos los campos, excepto 'Notas', son requeridos para crear una nueva reserva";
      //     return;
      //   }

      this.$store
        .dispatch("createReservation", { ...this.fields, date: moment(this.fields.date).format("YYYY-MM-DD") })
        .then((response) => {
          if (response) {
            this.visible = false;
          } else {
            this.error = "No hay lugar disponible para la fecha y hora seleccionada";
          }
        })
        .catch((error) => (this.error = error.message));
    },
  },
});
