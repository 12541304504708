<style scoped>
div.card {
  border: solid 1px;
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 8px;
  vertical-align: top;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

div.card.loading::after {
  content: "Cargando...";
  font-weight: bold;
  color: grey;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

div.card > div.image {
  z-index: 11;
  position: relative;
  top: -30px;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  float: right;
  padding: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

div.card > div.title > .subtitle {
  color: #777777;
  font-weight: 500;
  letter-spacing: 2px;
}

div.card > div.title > .subtitle > span {
  margin-left: 5px;
  font-style: italic;
  color: #b4b4b4;
  font-weight: 300;
}

div.card > div.buttons {
  margin-top: 30px;
  margin-bottom: 15px;
}

div.card > div.buttons > button {
  text-transform: uppercase;
  letter-spacing: 2px;
}

div.card > div.buttons > button + button {
  margin-left: 10px;
}

/* Comanda card */
div.card.comanda {
  border: solid 1px #ff6227;
}

div.card.comanda > div.image,
div.card.comanda > div.buttons > button.btn-confirm {
  background-color: #ff6227;
}

/* Mozo card */
div.card.mozo {
  border: solid 1px #f7c43b;
}

div.card.mozo > div.image,
div.card.mozo > div.buttons > button.btn-confirm {
  background-color: #f7c43b;
}

/* Reserva card */
div.card.reserva,
div.card.reserva_pendiente {
  border-color: #6be29d;
}

div.card.reserva_pendiente > div.buttons > button.btn-confirm {
  background-color: #6be29d;
}

div.card.reserva_pendiente > div.buttons > button.btn-cancel {
  color: #6be29d;
  border: 1px solid #6be29d;
}

div.card.reserva_activa,
div.card.reserva_perdida {
  border: none;
}

div.card.reserva_cancelada,
div.card.reserva_cancelada_cliente {
  border: none;
  background-color: #e8e8e8;
}
</style>

<template>
  <div :class="`card ${type} ${loading ? 'loading' : ''}`">
    <div class="image">
      <img
        v-if="hasImage"
        :src="require(`../assets/img/${type}.svg`)"
        :alt="type"
      />
    </div>

    <div class="content">
      <h3 class="subtitle">
        {{ subtitle }}
        <span>{{ fromNow }}</span>
      </h3>

      <h2 class="title">{{ title }}</h2>

      <slot />
    </div>

    <div v-if="hasButtons" class="buttons">
      <button class="btn btn-filled btn-confirm" @click="handleConfirm">
        {{ confirmText }}
      </button>
      <button class="btn btn-cancel" @click="handleCancel">
        {{ cancelText }}
      </button>

      <div v-if="error" style="color: red">
        <small>Algo salió mal. Intentelo nuevamente.</small>
      </div>
    </div>
  </div>
</template>

<script>
import { moment } from "moment";

export default {
  props: {
    loading: { type: Boolean, required: false, default: false },
    error: { type: Error, required: false, default: null },
    timeStamp: { type: Date, required: false, default: null },
    title: { type: String, required: false, default: "" },
    subtitle: { type: String, required: false, default: "" },
    type: {
      type: String,
      required: true,
      validator: (val) =>
        [
          "comanda",
          "mozo",
          "reserva",
          "reserva_pendiente",
          "reserva_activa",
          "reserva_perdida",
          "reserva_cancelada",
          "reserva_cancelada_cliente",
        ].indexOf(val) !== -1,
    },
  },
  computed: {
    hasImage() {
      return this.type == "comanda" || this.type == "mozo";
    },
    hasButtons() {
      return (
        this.type == "comanda" ||
        this.type == "mozo" ||
        this.type == "reserva_pendiente"
      );
    },
    confirmText() {
      switch (this.type) {
        case "comanda":
          return "Aceptar";
        case "mozo":
          return "Recibido";
        case "reserva_pendiente":
          return "Llegó";
        default:
          return "Confirmar";
      }
    },
    cancelText() {
      switch (this.type) {
        case "reserva_pendiente":
          return "No vino";
        default:
          return "Cancelar";
      }
    },
    fromNow() {
      return this.timeStamp != null ? moment(this.timeStamp).fromNow() : "";
    },
  },
  methods: {
    handleConfirm() {
      this.$emit("confirm", this.type);
    },
    handleCancel() {
      this.$emit("cancel", this.type);
    },
  },
};
</script>