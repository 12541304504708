import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import App from './App.vue'

import { routes } from './routes';
import ToggleButton from 'vue-js-toggle-button'
import VCalendar from "v-calendar";

Vue.use(ToggleButton);

// Use v-calendar & v-date-picker components
// https://vcalendar.io/
Vue.use(VCalendar, {});

(window as any).firebase = firebase;

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.config.productionTip = false;

const files = require.context('./elements', true, /\.vue$/i);
files.keys().map(key => {
    const tokens = key.split('/');
    if (tokens.length > 0) {
        const name = tokens.pop();
        if (name) Vue.component(name.split('.')[0], files(key).default)
    }
});

const router = new VueRouter({ routes });

console.debug("Initializing app...");

const store = require('./store/index').default;

Vue.directive('focus-on-create', {
    bind: function (el: HTMLElement) {
        // Focus the element
        Vue.nextTick(() => {
            if (el instanceof HTMLInputElement && (el.value == "Nombre del Producto")) {
                el.focus();
                el.select();
            }
        })
    }
})

// Crea la nueva aplicación de Vue.js
const VueApp = new Vue({
    router, store, render: (h: Function) => h(App)
});

// Monta la app para que sea visible.
VueApp.$mount('#app');

// Inicializa la aplicación de firebase.
firebase.initializeApp({
    apiKey: "AIzaSyAZelDDCTP8lUsONa9df4QcxJ1WChBo67Q",
    authDomain: "deautor-a1.firebaseapp.com",
    databaseURL: "https://deautor-a1.firebaseio.com",
    projectId: "deautor-a1",
    storageBucket: "deautor-a1.appspot.com",
    messagingSenderId: "208796409812",
    appId: "1:208796409812:web:a23b67100aed0356b4c041",
    measurementId: "G-ECYPFHZ0ZE"
});

// Setea firebase para que utilize los emuladores de localhost.
//firebase.functions().useEmulator("localhost", 5001);

// Redirige al root de la app luego de cada inicio o cierre de sesión.
if (VueApp.$router.currentRoute.path != '/') {
    VueApp.$router.push('/');
}

// En base al estado de autenticación de firebase, inicializa la app de Vue como corresponde.
firebase.auth().onAuthStateChanged((user) => {
    VueApp.$store.state.userTokenReady = false;

    // Si hay un usuario con la sesión iniciada, lo redirecciona a la sección correspondiente.
    if (user) {
        // Primero obtiene las características de su cuenta de usuario.
        user.getIdTokenResult().then((result) => {
            VueApp.$store.dispatch('initializeAccountData', { id: user.uid, token: result }).then((isAdmin) => {
                // Si el usuario es admin, lo redirecciona a la sección de admins.
                if (isAdmin) {
                    console.debug("App initialized for admin");
                    if (VueApp.$router.currentRoute.path != '/admin') {
                        VueApp.$router.push({ name: 'dashboard.admin.restaurants.index' });

                        VueApp.$store.dispatch('getPosts');
                    }
                }
                // Si no, lo redirecciona a la sección inicial de la app.
                else {
                    console.debug("App initialized for normal user");
                    if (VueApp.$router.currentRoute.path != '/dashboard') {
                        VueApp.$router.push('/dashboard');
                    }
                }

                VueApp.$store.state.userTokenReady = true;
            });
        });
    }
    // Si no hay usuario con sesión iniciada, redirige al login.
    else if (VueApp.$router.currentRoute.path != '/login') {
        VueApp.$router.push('/login');
        VueApp.$store.state.userTokenReady = true;
    }
});