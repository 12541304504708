import Vue from 'vue';

export default {
    state: {
        tableStatus: {},
    },
    mutations: {
        SET_TABLE_STATUS(state, { tableId, status, session }) {
            console.debug('Tables/mutations/SET_TABLE_STATUS\ntableId:', tableId, 'status:', status, 'session:', session);
            Vue.set(state.tableStatus, tableId, { status, session });
        }
    },
    actions: {
        onTableChange({ rootState, commit }, { table }) {
            console.debug("Tables/actions/onTableChange\ntable:", table);

            const sessionId = table.data().tableSessionId;

            const ordersHistoryCollection = window.firebase.firestore().collection("restaurants").doc(rootState.restaurant.id).collection("ordersHistory");

            if (sessionId) {
                ordersHistoryCollection.doc(sessionId).get().then(document => {
                    // If the document exists, then uses it as the new current one.
                    if (document.exists)
                        ordersHistoryCollection.doc(sessionId).collection('events').get().then(eventsSnapshot => {
                            // Parses each event
                            let events = [];
                            eventsSnapshot.forEach(event => {
                                const type = event.data().eventType;

                                let data = event.data().data;
                                
                                if (type == 'ORDER_PLACED') {
                                    data = data.map(product => {
                                        return {
                                            quantity: product.quantity,
                                            productId: product.idProduct,
                                            productName: rootState.menu.products.find(({ id }) => id == product.idProduct).productName
                                        }
                                    });
                                }

                                events.push({
                                    id: event.id,
                                    timeStamp: new Date(parseInt(event.id)),
                                    type,
                                    data
                                });
                            });

                            const session = {
                                id: sessionId,
                                tableId: table.id,
                                tableName: rootState.restaurant.activeTables[table.id],
                                events
                            };

                            commit('SET_TABLE_STATUS', { tableId: table.id, status: table.data().mainStatus, sessionId, session });
                        });
                    // Otherwise, uses an empty session
                    else
                        commit('SET_TABLE_STATUS', { tableId: table.id, status: table.data().mainStatus, session: null });
                });
            }
            else
                commit('SET_TABLE_STATUS', { tableId: table.id, status: table.data().mainStatus, session: null });
        },
    },
    getters: {
        tablesList(state, getters, rootState) {
            let tables = [];

            for (const [tableId, tableStatus] of Object.entries(state.tableStatus))
                tables.push({
                    id: tableId,
                    name: rootState.restaurant.activeTables[tableId],
                    status: tableStatus.status,
                    sessionId: tableStatus.sessionId,
                    session: tableStatus.session,
                    selected: true,
                    enabled: true,
                });

            return tables;
        },
        eventsList(state, getters) {
            let events = [];

            getters['tablesList'].forEach(table => {
                if (table.session != null)
                    table.session.events.forEach(event => {
                        events.push({ tableId: table.id, tableName: table.name, ...event });
                    });
            });

            return events.sort((a, b) => b.timeStamp - a.timeStamp);
        }
    }
}