<template>
	<textarea v-model="internalValue" @blur="onInput" @keyup="handleKeyUp" @keypress.enter="onInput" />
</template>

<script>
export default {
	props: {
		value: { type: [String, Number], required: true },
	},
	data() {
		return {
			internalValue: this.value,
			timeoutId: null,
			timeoutTime: 1000,
		};
	},
	methods: {
		handleKeyUp(e) {
			if (e.keyCode != 13) {
				if (this.timeoutId) clearTimeout(this.timeoutId);
				this.timeoutId = setTimeout(this.onInput, this.timeoutTime);
			}
		},
		onInput() {
			if (this.value != this.internalValue) {
				if (this.timeoutId) {
					clearTimeout(this.timeoutId);
					this.timeoutId = null;
				}
				this.$emit("input", this.internalValue);
			}
		},
	},
};
</script>