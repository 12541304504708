














































































import Vue from "vue";
import { Post } from "models";

export default Vue.extend({
  computed: {
    posts() {
      return this.$store.state.posts.posts;
    },
  },
  methods: {
    create() {
      this.$router.push({ name: "dashboard.admin.posts.create" });
    },
    onPostSelection(post: Post) {
      this.$router.push(`/dashboard/admin/posts/create?id=${post.id}`);
    },
  },
});
